import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Linking} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import moment from 'moment';
import {Copy} from '../../../../assets/component';
import COLORS from '../../../../assets/component/colors';


export default function Types(props) {

  const {types,active,set} = props



      return(
         <View style={[styles.container]}>
            <Text>View by : </Text>
              {types.map(key => {
                    return (
                        <TouchableOpacity key={key} style={[styles.box,{backgroundColor:key == active ? '#aaa' : '#fef'}]} onPress={()=>{set(key)}}>
                            <Text style={[styles.text,{color:key == active && '#fff'}]}> {key} </Text>
                        </TouchableOpacity>
                    ); 
             })}
         </View>
     )







}

const styles = StyleSheet.create({
  container:{
    flexDirection:'row',
    padding:8,
    marginVertical:5,
    alignItems:'center',
    backgroundColor:'#e4e4e4'
  },
  box:{
   padding:4,
   borderRadius:4,
   borderWidth:1,
   borderColor:'#ddd',
   marginRight:5
  }
});
