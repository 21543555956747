import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text} from 'react-native';



export default function ProductsCol(props) {

    const {products,bill} = props

    const ProductListTH = () => {

      return(
       <View style={{flexDirection:'row'}}>
         <Text style={[styles.th,{flex:3}]}>NAME</Text>
         <Text style={[styles.th,{flex:1}]}>QTY</Text>
         <Text style={[styles.th,{flex:1}]}>AMOUNT</Text>
       </View>
      )
     }
     
     const ProductList = () => {
       if(!products.length) return null
   
       const listItems = products.map((id,key) =>
         <TouchableOpacity key={key} onPress={()=>console.log(id.toString())} style={[styles.paymentList,{backgroundColor:bill.id == id ? '#aaa' : null}]}>
           <View style={{flexDirection:'row'}}>
             <Text style={{flex:3}}>{id.name}</Text>
             <Text style={{flex:1}}>{id.qty}</Text>
             <Text style={{flex:1}}>RM{id.amount}</Text>
           </View>
           <Text style={{flex:1,fontSize:10,color:'#0082e6'}}>{id.id}</Text>
         </TouchableOpacity>
       );
       return listItems;
     }

     return (
      <View>
              {ProductListTH()}
              {ProductList()}
      </View>
     )



}

const styles = StyleSheet.create({
  text:{
    textTransform: 'capitalize',
    fontSize:16,
  },
  tabActive:{
    borderWidth:1,
    borderTopLeftRadius:10,
    borderTopRightRadius:10,
    borderBottomWidth:0,
    borderColor:'#ccc',
    borderBottomColor:'#fff',
    backgroundColor:'#fff'
  },
  tabInActive:{
    borderBottomWidth:1,
    borderColor:'#ccc'
  },
});
