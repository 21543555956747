import React, { useRef,useEffect  } from "react";
import {View,Text,StyleSheet,TouchableOpacity,Image} from "react-native";
import { manipulateAsync, FlipType, SaveFormat } from 'expo-image-manipulator';
import Ionicons from '@expo/vector-icons/Ionicons';


export default function FilePicker(props) {

    const {set,value,resize,size} = props;

    const inputFile = useRef(null) 


    const onButtonClick = () => {
     inputFile.current.click();
    };

    function handleChange(event) {
      if (event.target.value.length == 0) return null

          if(event.target.files[0].type.split('/')[0] === 'image'){
              const fileSize = event.target.files[0].size
              const cmprs = fileSize > 1000000 ? 0.1 : 1
              Compress(URL.createObjectURL(event.target.files[0]),cmprs) 
          }else{
              set(event.target.files[0])
          }

    }

  const Compress = async (uri,cmprs) => {
      const Resize = resize?resize:[]
      const manipResult = await manipulateAsync(
          uri,
          Resize,
          { compress: cmprs }
    );
    set(manipResult.uri)
  };

  const Logo = () => {

    const Size = {width:size?size:50 , height:size?size:50}

    if(!value){

      return (
        <View style={[styles.logo,Size]}>
            <Ionicons name={'document-text'} color={'#ccc'} size={25} />
        </View>
      )

    }

    if(value.name){
        return (
            <View style={{flexDirection:'row',paddingVertical:10}}>
                <View style={styles.tinyDoc}>
                    <Ionicons name={'document-text'} color={'#aaa'} size={18} />
                </View>
                <Text ellipsizeMode='tail' numberOfLines={1}>{value.name}</Text>
            </View>
        )
    }

    return (
      <View style={{flexDirection:'row',paddingVertical:10}}>
                <Ionicons name={'document-text'} color={'#aaa'} size={18} />
                <View style={{flex:1}}>
                    <Text ellipsizeMode='tail' numberOfLines={1}>{value}</Text>
                </View>

      </View>
    )
  }



    return (
        <View style={styles.container} {...props}>
            <input type='file' id='file' ref={inputFile}  style={{display: 'none'}}  onChange={handleChange}/>
            <TouchableOpacity onPress={onButtonClick} >
                <Logo/>
            </TouchableOpacity>
        </View>
    );

  }


    const styles = StyleSheet.create({
      tinyLogo:{
        width:50,
        height:50,
        backgroundColor: 'transparent'
      },
     logo:{
        borderWidth:1,
        borderColor:'#eee',
        borderRadius:5,
        alignContent:'center',
        alignItems:'center',
        justifyContent:'center',
        width:50,
        height:50,
      }
    
});

  