import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,ActivityIndicator} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import {TextInput,Modal,Button,ListDownoverlay} from "../../../assets/component";
import {UpdateOrderShopee,GetAllDocs,CancelOrder } from "../../../assets/js/firebase";
import Products from '../Order/add/productslist'
import COLORS from "../../../assets/component/colors";

export default function addModal(props) {

    const {clear,data,update,cancel} = props


    const [modalProducts, setmodalProducts] = useState(false);
    const [isLoading, setIsloading] = useState(false);

    const [orderid, onOrderid] = useState("");
    const [company, onCompany] = useState("Shopee");
    const [productList, setProductList] = useState(null);

    const [error, setError] = useState("");
    const [selectedPrev, setSelectedPrev] = useState([]);
    const [selected, setSelected] = useState([]);


    useEffect(() => {
        if(data){
            setSelectedPrev(data.products)
            setSelected(data.products)
            onOrderid(data.orderid)
            onCompany(data.company)
        }
    }, [data]);

    useEffect(() => {
      const getDatas = async () => {
        const ListProduct = await GetAllDocs('products','active')
        setProductList(ListProduct)
      }
      getDatas()
    }, []);


    const onChangePrice = (index,val) => {
        setSelected((prev) =>
          prev.map((userScore,idx) => {
            if (idx === index) {
              return {
                ...userScore,
                amount: val?val.replace(/^0+/, ''):'0'
              };
            } else {
              return userScore;
            }
          })
        );
      };

      const onChangeQty = (index,val) => {
        const qty = val?val:0
        setSelected((prev) =>
          prev.map((userScore,idx) => {
            if (idx === index) {
              return {
                ...userScore,
                qty: parseInt(qty)
              };
            } else {
              return userScore;
            }
          })
        );
      };



    async function Validate(){
        setError('')

        const Income = selected.reduce((n, {amount,qty}) => n + (parseFloat(amount)*parseInt(qty)), 0).toFixed(2)

        var valid = Validation(orderid,onOrderid);
        var valid2 = ValidationAmt(Income);
   
        if(valid && valid2 ){
          setIsloading(true)
          const Added = await UpdateOrderShopee({
            'orderid':orderid,
            'company':company,
            'income':Income,
            'products':selected
          },data.id,selectedPrev)
          if(Added){
            update(Added)
          }
          ClearState()
        }

    }

    function Validation(input,change){
        if(!input || input.error){
            change({error:"Invalid Value"});
            return false
        }
        return true
    }

    
    function ValidationAmt(input,change){

      if(input <= 0 || isNaN(input) || input.error){
        return false
      }
      return true
    }


    function ClearState(){
        onOrderid("")
        setIsloading(false)
        setSelected([])
        clear(null)
    }



    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }


    function RemoveProducts(index){
        const temp = [...selected];
        temp.splice(index, 1);
        setSelected(temp);
        if(!temp.length){
            setmodalProducts(true)
        }
    }

    async function VerifyRemove(){
        if (confirm('Are you sure you want to delete ?')) {
          RemoveOrder()
        } else {
          console.log('Cancel Delete');
        }
    }

    async function RemoveOrder(){
      setIsloading(true)
      const status = await CancelOrder(data.id,'online_order')
      if(status){
        cancel(data)
        ClearState()
      }else{
        setError("Error Remove")
      }
      setIsloading(false)
    }

    const headerProducts = () => {
      return (
        <View style={{flexDirection:'row',padding:2}}>
          <View style={{flex:1}}><Text style={styles.th}>ID</Text></View>
          <View style={{width:50}}><Text style={styles.th}>QTY</Text></View>
          <View style={{width:100}}><Text style={styles.th}>PRICE</Text></View>
          <View style={{width:20}}></View>
        </View>
      )
    }


    const ProductSelected = (item) => {

      function qtyChanger(i,val,stock){
        if(val <= stock){
          onChangeQty(i,val,stock)
        }
      }

      const aaa = () => item.map((i, index) => {

          const Stock = i.type == 'stock'?true:false

          return (
              <View style={[styles.orderSelect,{ backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }]} key={"Selected"+index}>
                  <View style={{flex:4}}>
                      <Text>{i.name}</Text>
                      {Stock &&
                        <View style={{flexDirection:'row'}}> 
                          <Text>stock : </Text>
                          <Text style={{color:COLORS.Link}}>{i.stock}</Text>
                        </View>
                      }
                  </View>
                  <View style={{width:50,marginRight:5}}>
                      {Stock && <TextInput onChangeText={(val)=>qtyChanger(index,val,i.stock)} value={i.qty}/>}
                  </View>
                  <View style={{width:100}}>
                      <TextInput onChangeText={(val)=>onChangePrice(index,val)} value={i.amount}/>
                  </View>
                  <TouchableOpacity onPress={()=>RemoveProducts(index)} style={{width:20}}>
                      <Ionicons name='close' size={18} color={COLORS.Error} />
                  </TouchableOpacity>
              </View>
          )
      })

      return (
          <View style={{marginBottom:5}}>
              <View style={{alignItems:'center',flexDirection:'row'}}>
                  <TouchableOpacity style={styles.additem} onPress={()=> setmodalProducts(true)}>
                    <Ionicons name='add-box' size={22} color={COLORS.Primary} />
                    <Text style={{color:COLORS.Primary,marginLeft:2}}>ADD ITEM</Text>
                  </TouchableOpacity>
              </View>
              {headerProducts()}
              {aaa()}
              <View style={{flexDirection:'row',alignContent:'flex-end',alignItems:'flex-end'}}>
                  <Text style={{fontWeight:'bold',fontSize:18}}>RM{selected.reduce((n, {amount,qty}) => n + (parseFloat(amount)*parseInt(qty)), 0).toFixed(2)}</Text> 
                </View> 
          </View>
      )
  }


    const OrderAddPopup = (data) => {
        return(
                <View style={{flex:1}}>
                        <View >
                            <TextInput onChangeText={onOrderid} maxLength={60} title="Shopee Order ID" value={orderid} autoCapitalize="true" errclear={true}/>
                            <ListDownoverlay name={'Company'} onPress={onCompany} list={['Shopee']} active={company}/>
                        
                        </View>
                        {ProductSelected(data)}
                        <Error error={error}/>
                        <ButtonView/>
                </View>
        )
    }

    const ButtonView = () => {
      if(isLoading) return <ActivityIndicator size={'small'}/>
      return (
        <View style={{flexDirection:'row-reverse'}}>
          <Button title={'UPDATE'} icon={'md-add-circle'} onPress={() => Validate()} />
          <Button title={'DELETE'} icon={'md-trash'} onPress={() => VerifyRemove()} style={{marginRight:5}} color={'#ed1144'}/>
        </View>
      )
    }

    function SelectProduct(data) {
      setSelected(data)
      setmodalProducts(false)
    }


    const Popup = () => {
        return (
                <Modal title={modalProducts?'Choose Product':'Update Order'} icon={'ios-receipt'} visible={data?true:false} setVisible={()=>ClearState()}>
                        <Text style={{fontSize:18}}>Order ID : {data && data.id}</Text>
                        {modalProducts?<Products setValue={(val)=>SelectProduct(val)} selected={selected} products={productList}/>:OrderAddPopup(selected)}

                </Modal>
        )
    }
    


    return(
        <View>
                {Popup()}
        </View>
    )

}

const styles = StyleSheet.create({
    container:{
      padding:10,
      flexDirection:'row',
      flexGrow:1,
      borderBottomWidth:1,
      borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      orderSelect:{
        borderRadius:5,
        padding:5,
        borderColor:'#aaa',
        flexDirection:'row',
        backgroundColor:'#eee'
      },
      additem:{
        borderWidth:1,
        padding:5,
        marginVertical:5,
        flex:1,
        borderStyle:'dashed',
        marginRight:10,
        borderRadius:5,
        borderColor:'#777',
        alignItems:'center',
        alignContent:'center'
      },
      input2:{
        width:50,
      },
      deliverybtn:{
        padding:10,
        alignContent:'center',
        alignItems:'center'
      },
      deliverytxt:{
        color:COLORS.Link,
        fontSize:14
      },
      additem:{
        borderWidth:1,
        padding:5,
        marginVertical:5,
        flex:1,
        borderStyle:'dashed',
        marginRight:10,
        borderRadius:5,
        borderColor:COLORS.Primary,
        alignItems:'center',
        alignContent:'center',
        flexDirection:'row',
        justifyContent:'center'
      },
  });
  