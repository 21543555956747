import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View} from 'react-native';
import {GetAllDocs} from "../../../assets/js/firebase";
import {Button,SearchBox} from "../../../assets/component";
import Tab from './tab';
import AddStaff from './add';
import Table from './table';


export default function ProductsScreen({navigation}) {


  const isMounted = useRef();

  const DafaultTab = {active:[],inactive:[]}


  const [list, setList] = useState(DafaultTab);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);
  const [addModal, setAddmodal] = useState(false);
  const [isLoading, setIsloading] = useState(false);


  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      if(list[tabactive].length == 0){
        setIsloading(true)
        getList(tabactive);
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [tabactive]);
  


  async function getList(active){

      const ListProduct = await GetAllDocs('staff',active)
      if(isMounted.current){
        setList({...list, [active]:ListProduct});
        setIsloading(false)
      }
  };


  function PushItem(data){
    const dataArray = [ ...list[data.status]]
    dataArray.unshift(data)
    setList({...list, [data.status]:dataArray});
    setAddmodal(false)
  }

  function StockNav(nav,id){
    navigation.navigate(nav, {pid:id})
  }
  





  return (
    <View style={styles.container}>
      <View style={styles.tabBx}>
        <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab} list={list}/>
        <View style={{flex:1}}/>
        <View style={{flexDirection:'row'}}>
          <SearchBox set={(val)=>setList({...list, [tabactive]:val})} products={list[tabactive]} filters={['name', 'mykad']}/>
          <View style={{padding:5,justifyContent:'center'}}><Button title={'ADD'} icon={'md-add-circle'} onPress={() => setAddmodal(true)}/></View>
        </View>
      </View>
      <Table 
        tab={tabactive} 
        setList={setList} 
        list={list} 
        loading={isLoading}
        nav={StockNav}
      />
       <AddStaff add={PushItem} visible={addModal} set={setAddmodal}/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
});
