import React from "react";
import { Page, Text, Image, Document, StyleSheet,View ,Font} from "@react-pdf/renderer";
import moment from 'moment';
import Helvetica from "../../../../assets/fonts/Helvetica.ttf";

export default function PdfPackingList(props) {

  const {user,details,customer} = props


  
  Font.register({ family: 'Helvetica', src: Helvetica });


  const Address = () => {
    return  (
      <View style={styles.address}>
              <Text style={{color:'#111',fontWeight:'700',fontSize:12}}>{user.name}</Text>
              <Text style={styles.stext}>({user.ssm})</Text>
              <View style={{flexDirection:'row',flexShrink: 1,textAlign:'left',width:122}}> 
                <Text style={[styles.text,{flex: 1,textAlign:'left'}]}>{user.address}</Text>
              </View>
              {Additional('website',user.website)}
              {Additional('Tel',user.tel)}
      </View>
    )
  }

  const Additional = (val,data) => {
    if(!data) return null
    return <Text style={styles.text}>{val} : {data}</Text>
  }


  const ReceiptDetails = (data) => {
    return (
      <View>
        <View style={{alignContent:'flex-end',alignItems:'flex-end'}}><Text style={styles.text2}>#{data.id}</Text></View>
          <View style={{flexDirection:'row',marginBottom:5}}>
              <View style={{marginRight:10}}>
                  <Text style={styles.text}>Name</Text>
                  <Text style={styles.text}>Company</Text>
                  <Text style={styles.text}>Mobile</Text>
              </View>
              <View style={{flex:1}}>
                  <Text style={styles.text}> : {data.name}</Text>
                  <Text style={styles.text}> : {data.company}</Text>
                  <Text style={styles.text}> : {data.mobile}</Text>
              </View>
              <View style={{marginRight:10}}>
                  <Text style={styles.text}>Address</Text>
              </View>
              <View style={{flex:1}}>
                  <Text style={styles.text}> : {data.address}</Text>
              </View>
              <View style={{marginRight:10}}>
                  <Text style={styles.text}>Remark</Text>
                  <Text style={styles.text}>Date</Text>
              </View>
              <View style={{flex:1}}>
                  <Text style={styles.text}> : {data.remark}</Text>
                  <Text style={styles.text}> : {data.date}</Text>
              </View>
          </View>
      </View>
    )
  }


  const TableTh = () => {
    return(
      <View style={{flexDirection:'row',borderWidth:0.5,paddingVertical:5}}>
        <View style={{flex:1,paddingHorizontal:5}}><Text style={styles.text}>ID</Text></View>
        <View style={{flex:1,marginRight:10}}><Text style={styles.text}>IMAGE</Text></View>
        <View style={{flex:6}}><Text style={styles.text}>DESCRIPTION</Text></View>
        <View style={[styles.center,{flex:2}]}><Text style={styles.text}>QTY</Text></View>
      </View>
    )
  }


  const ListItem = (data) => {
    return(
        <View>
            {Remark(details.remark)}
            {data.map((item,key) => {

              return (
                <View key={key} style={styles.listbx}>
                  <View style={{flex:1,justifyContent:'center',paddingHorizontal:5}}><Text style={styles.text}>{item.pid}</Text></View>
                  <View style={{flex:1,justifyContent:'center',marginRight:10}}><Image style={styles.icon}  source={{ uri: item.image}} /></View>
                  <View style={{flex:6,justifyContent:'center'}}><Text style={styles.text}>{item.name}</Text></View>
                  <View style={[styles.center,{flex:2,justifyContent:'center'}]}><Text style={styles.text}>{item.qty}</Text></View>
                </View>
              ); 
            })}
        </View>
    )
}

const Remark = (data) => {
  if(!data) return null
  return(
    <View style={{flexDirection:'row',marginTop:10}}>
          <View style={{borderWidth:1,padding:5,borderRadius:5,borderStyle:'dashed'}}>
      <Text style={{fontSize:10}}>Remark : {details.remark}</Text>
    </View>

    </View>
  )
}

const Paid = () => {
  return (
    <View style={{flexDirection:'row',justifyContent:'flex-end',marginVertical:10}}>
        <View style={styles.paid}>
            <Text style={styles.paidtxt}>PAID</Text>
        </View>
    </View>
  )
}


const ReformatTime = (data) => {
  if(!data) return null
  return moment(data.toDate()).format('YYYY-MM-DD hh:mm:ss');
}

  return (
    <Document>
      <Page size="A4" style={styles.body}>

          <View style={styles.header} fixed>
            <Image style={styles.image}  source={{ uri: user.logo}} />
            <View style={{flex:1}}/>
            {Address()}
          </View>

          {ReceiptDetails(customer)}

          <View style={[styles.center,{marginBottom:20}]}><Text style={{fontSize:12,fontWeight:700}}>PACKING LIST</Text></View>



          {TableTh()}
          {ListItem(details)}

          {details.updated && <Text style={[styles.stext,{marginLeft:20}]}>updated : {ReformatTime(details.updated)}</Text>}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} / ${totalPages}`
          }
        />
        <Paid/>
      </Page>
    </Document>
  );
};


const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    width:'50%'
  },
  address:{
    alignItems:'flex-start',
  },
  text: {
    fontSize: 10,
    fontFamily: "Helvetica",
    color:'#111',
    marginBottom:3
  },
  text2: {
    fontSize: 18,
    fontWeight:'bold',
    fontFamily: "Helvetica",
    color:'#ea0f57',
    marginBottom:3
  },
  stext:{
    fontSize: 9,
    fontWeight:'200',
    fontFamily: "Helvetica",
    color:'#111',
    marginBottom:2
  },
  btext: {
    fontSize: 12,
    fontWeight:'200',
    fontFamily: "Helvetica",
    color:'#111',
  },
  image: {
    width:100,
    height:100,
  },
  header: {
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center'
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "#777",
  },
  center:{
    alignContent:'center',
    alignItems:'center',
    flexDirection:'row',
    justifyContent:'center',
    textAlign:'center'
  },
  icon:{
    width:50,
    height:50,
  },
  listbx:{
    flexDirection:'row',
    paddingVertical:5,
    borderBottomWidth:0.5,
    borderLeftWidth:0.5,
    borderRightWidth:0.5
  },
  paid:{
    borderWidth:3,
    borderColor:'#0db700',
    borderRadius:5,
    padding:5,
    flexDirection:'row'
  },
  paidtxt:{
    fontSize:24,
    color:'#0db700',
    fontWeight:'700',
    fontFamily:'Helvetica'
  }
});
