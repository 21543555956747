import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Dimensions} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import moment from 'moment';
import { LineChart } from "react-native-chart-kit";



export default function CharsLine(props) {

  const {list} = props

  const [item, SetItem] = useState([]);


  useEffect(() => {
    if(list){
      Categorised(list)
    }

  }, [list]);

  function Categorised(data){

      const result = data.reduce((all, el) => {
        const Mth = kF(el.date.seconds)
          all[Mth] = (all[Mth] || 0) + Number(el.amount); 
        return all;
      }, {});

      const obj3 = []

      moment.monthsShort().map((val)=> {
        if(result[val]){
          obj3.push(result[val]/1000)
        }else{
          obj3.push(0)
        }
      })

      SetItem(obj3);
      
  }





  const MyLineChart = () => {


    const SizeMobile = screenWidth < 762 ? 20 : 200

    const screenWidth = Dimensions.get('window').width - 20
  
    const data = {
      labels: moment.monthsShort(),
      datasets: [
        {
          data: item,
          color: (opacity = 1) => `rgba(134, 65, 244, ${opacity})`, // optional
          strokeWidth: 2 // optional
        }
      ],
    };
    
    return (
      <View>


<LineChart
  data={data}
  width={screenWidth}
  height={170}
  yAxisLabel={'RM'}
  yAxisSuffix={"K"}
  chartConfig={{
    backgroundColor: '#1cc910',
    backgroundGradientFrom: '#eff3ff',
    backgroundGradientTo: '#efefef',
    color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
    style: {
      borderRadius: 16,
    },
    decimalPlaces : 0,
    propsForDots: {
      r: "3",
      strokeWidth: "1",
      stroke: "#fff"
  }
    
  }}
  style={{
    marginVertical: 8,
    borderRadius: 5,
  }}
/>
        </View>

    );
  };

  function kF(date) {

    return moment.unix(date).format("MMM")

  }


  
  return  <MyLineChart/>



}

const styles = StyleSheet.create({
  text:{
    fontSize:16
  }
});
