import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,Alert, ActivityIndicator} from 'react-native';
import * as ImageManipulator from 'expo-image-manipulator';
import {getStorage} from "firebase/storage";
import Ionicons from '@expo/vector-icons/MaterialIcons';
import {Button,ImagePicker,ListDown,TextInput,Modal} from "../../../assets/component";
import COLORS from "../../../assets/component/colors";
import {UpdateData} from "../../../assets/js/firebase";
import {UploadData} from "../../../assets/js/upload";


export default function UpdateProduct(props) {

    const {data,clear,update} = props

    const storage = getStorage();

    const [isLoading, setIsloading] = useState(false);


    const [name, onName] = useState('');
    const [description, onDescription] = useState('');
    const [code, onCode] = useState('');
    const [size, onSize] = useState('');
    const [type, onType] = useState('');

    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");


    useEffect(() => { 
        if(data){
            onName(data.name)
            onDescription(data.desc)
            onType(data.type)
            setImage(data.image)
        }
    }, [data]);



    function handleChange(event) {
        if (event.target.value.length == 0) return null

        Compress(URL.createObjectURL(event.target.files[0])) 


    }

    const Compress = async (uri) => {
        const manipResult = await ImageManipulator.manipulateAsync(
            uri,
            [],
            { compress: 0.4 }
      );
      setFile(manipResult.uri)
    };




    


    async function Validate(data){
        setError('')
        var valid = true;

        if(!name){
            onName({error:"No Name"});
            valid=false
        }
   
        if(valid){
            setIsloading(true)
            data['name'] = name
            data['desc'] = description
            data['code'] = code
            data['size'] = size
            
            if(file && type == 'stock'){
                const UploadedURL  = await UploadData(data.id,file,'products')
                if(UploadedURL){
                    data['image'] = UploadedURL
                    Success(data) 
                }
            }else{
                Success(data) 
            }
        }

    }

 
    async function Success(data){
        await UpdateData('products',data.id,data)
        setIsloading(false)
        onName("")
        onDescription("")
        onType("")
        setFile("")
        setImage("")
        clear()
    }



    const InputView = () => {
        return (
            <View style={{flexDirection:'row',marginVertical:10}}>
                <input  accept="image/*" type="file" onChange={handleChange} style={{flex:1}}/>
                {file?
                <TouchableOpacity onPress={()=> setFile(null)}>
                    <Ionicons name='close' size={18} color={COLORS.Error} />
                </TouchableOpacity>
                :null}
            </View>
        )
    }




        if(!data) return null


        return (
                <Modal title={data.id} visible={data?true:false} setVisible={clear}>
                        <View >
                            <Text>{error}</Text>
                            <TextInput title={'Name'} onChangeText={onName} value={name} />
                            <TextInput title={'Description'} onChangeText={onDescription} value={description} />
                            <TextInput title={'Code'} onChangeText={onCode} value={code} />
                            <TextInput title={'Size'} onChangeText={onSize} value={size} />
                            <ListDown list={['stock','service']} active={type} onPress={onType}/>
                            {image?<ImagePicker set={setFile} value={image} resize={[{ resize: { width: 100, height: 100 } }]} accept="image/*"/>:InputView()}
                        </View>
                        {!isLoading?<Button title={'UPDATE'} color={'#056a7a'} icon={'md-add-circle'} onPress={() => Validate(data)}/>:<ActivityIndicator size={'small'}/>}
                </Modal>
        )

}

const styles = StyleSheet.create({
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
  });
  