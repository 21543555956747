import React, { useState,useEffect } from 'react';
import { StyleSheet ,View, ActivityIndicator,Switch,TouchableOpacity } from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import Clipboard from '@react-native-clipboard/clipboard';
import {ListDownoverlay,Button,Modal,TextInput,Copy,Text,Form} from "../../../../assets/component";
import COLORS from "../../../../assets/component/colors";
import {UpdateDoc,CURRENT_ACC,AddDoc} from "../../../../assets/js/firebase";
import {ValidMobile} from "../../../../assets/js/validate";


export default function Update(props) {

    const {clear,data,update,tab} = props

    const [values,setValues] = useState(null)

    const [isLoading, setIsloading] = useState(false);
    const [account, setAccount] = useState(null);
    const [newacc, setNewacc] = useState(false);



    const BankLIST = ['MAYBANK','CIMB','BANK ISLAM','AFFIN','ALLIANCE','AMBANK','BANK RAKYAT','HLB','HSBC','MUAMALAT','OCBC','PBB','RHB','UOB','BSN']


    useEffect(() => {
      GetAccount()
    }, []);




    
    useEffect(() => {
      if(data && data.id){
        const NewData = {...data}
        NewData['status'] = data.status == 'active' ? true : false
        setValues(NewData)
        setNewacc(false)
      }
      
      if(data && data.add){
        setValues({'status':true})
        setNewacc(true)
      }

    }, [data]);


    async function GetAccount(){
      const Acc = await CURRENT_ACC()
      setAccount(Acc.id)
    }


    function changeHandler (val,type){
      setValues( prevValues => {
        return { ...prevValues,[type]: val}
      })
    }




    async function Validate(){

      const validName = Validation(values.name,'name');

      const validMobile = ValidatePhone(values.mobile,'mobile')


 
      if(validName && validMobile){
        setIsloading(true)
        var Updated ;
        const NewData = {...values} 
        NewData['mobile'] = validMobile
        NewData['status'] = NewData.status?'active':'inactive'
        if(data.id){
          Updated = await UpdateDoc(data.id,'transporter_list',NewData)
        }else{
          NewData['status'] = 'active'
          Updated = await AddDoc('transporter_list',NewData)
        }
        if(Updated){
          update(Updated)
          ClearState()
        }else{
          console.log("Unable to update")
        }
      }

  }


  
  function Validation(input,change){
    if(!input || input.error){
      changeHandler({error:"Invalid Value"},change)
      return null
    }
    return input
}


    function ValidatePhone(data,change) {
        const Input = ValidMobile(data);
        if(Input){
          return Input
        }else{
          changeHandler({error:"Invalid Value"},change)
          return null;
        }
    }

    function ClearState(){
      setIsloading(false)
      clear(null)
    }

    const AccountLink = () => {

      if(newacc) return  null

      const Link = 'http://deliverydkz.web.app/account/'+account+'/'+data.id

      return (
        <Copy link={Link} title={'Account Link'}/>
      )
    }



    const ButtonRow = () => {
      return (
        <View style={{flexDirection:'row'}}>
            <View style={{flex:1}}/>
            <Button title={newacc?'ADD':'Update'} isloading={isLoading}  icon={newacc?'add-circle':'md-checkmark'} onPress={() => Validate()}/>
        </View>
      )
    }




    if(!data || !account) return null


        return (
                <Modal title={newacc?'ADD NEW':'Update'} icon={'truck'}  Iconfont={'MaterialCommunityIcons'} visible={data?true:false} setVisible={()=>ClearState()}>
                          
                          <Form 
                            data={[
                                    [{name:'status',display:'Status',toggle:true}],
                                    [{name:'name',display:'Full Name'}],
                                    [{name:'mobile',display:'Mobile'}],
                                    [{name:'customer_id',display:'SSM/MYKAD'}],
                                    [{name:'in-house',display:'Company Transport',toggle:true,details : 'Enable this if transporter dont have to be billed'}],
                                    ['BANK'],
                                    [{name:'bank',display:'Bank',flex:1,listdown:BankLIST}],
                                    [{name:'account',display:'Account No'}],
                                  ]}
                              value={values}
                              set={setValues}
                            />
                          <AccountLink/>
                          <ButtonRow/>
                          
                </Modal>
        )

    

}

const styles = StyleSheet.create({
    container:{
      padding:10,
      flexDirection:'row',
      flexGrow:1,
      borderBottomWidth:1,
      borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      orderSelect:{
        borderRadius:5,
        padding:5,
        borderColor:'#aaa',
        flexDirection:'row',
        backgroundColor:'#eee'
      },
      input2:{
        width:50,
      },
      deliverybtn:{
        padding:10,
        alignContent:'center',
        alignItems:'center'
      },
      deliverytxt:{
        color:COLORS.Link,
        fontSize:14
      },
      bullet:{
        width:8,
        height:8,
        borderRadius:8,
        borderColor:'#428876',
        borderWidth:1,
        backgroundColor:'#428876'
      },
      bullet2:{
        width:8,
        height:8,
        borderRadius:8,
        borderColor:'#428876',
        borderWidth:1
      }
  });
  