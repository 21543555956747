import { useWindowDimensions} from 'react-native';

export function isMobile() {
  const dimensions = useWindowDimensions();

  return dimensions.width < 768;
}
  

  
  