import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,ActivityIndicator,Linking,Image} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import {ValidMobile} from "../../../../assets/js/validate";
import {Copy,Modal,Button,TextInput,Form} from "../../../../assets/component";
import COLORS from "../../../../assets/component/colors";
import {CURRENT_ACC,GetAllDocs} from "../../../../assets/js/firebase";
import Searchinput from './search';
import Progress from "./component/progress";
import DOLINK from "./component/dolink";
import PROOF from "./component/proof";
import SuggestList from './component/suggest';
import {UpdateDriver,DeleteDelivery} from "../../../../assets/js/firebase/delivery";


export default function DeliveryModal(props) {

    const {clear,data,update,type,modal,cancel} = props

    const orderType = data && data.type?data.type:type
    
    const BankLIST = ['CASH','MAYBANK','CIMB','BANK ISLAM','AFFIN','ALLIANCE','AMBANK','BANK RAKYAT','HLB','HSBC','MUAMALAT','OCBC','PBB','RHB','UOB','BSN']

    const [isLoading, setIsloading] = useState(false);
    const [values,setValues] = useState({})
    const [driver,setDriver] = useState({})

    const [account, setAccount] = useState(null);
    const [transporterList, setTransporterList] = useState([]);

    useEffect(() => {
      GetAccount()
      getTransporters()
    }, []);


    useEffect(() => {

      if(modal == 'order'){ SetOrder(data)}
      if(modal == 'delivery'){ SetDelivery(data)}

    }, [data]);

    function SetOrder(data){
      if(data){
        if(data.driver){
          setDriver(data.driver)
        }else{
          setDriver({})
        }
        setValues(data)
        if(data.delivery){
          changeHandler(data.delivery,'address')
        }
      }
    }

    function SetDelivery(data){
      if(data){

        // differents
       if(data.transporter){
         setDriver(data.transporter)
       }else{
         if(data.name){
           setDriver({'name':data.name,'mobile':data.mobile,'id':data.driver_id,'in-house':data.company_transporter})
         }else{
           setDriver({})
         }
       }
       
       setValues(data)
       if(data.delivery){
         changeHandler(data.delivery,'address')
       }
     }
    }



    async function getTransporters(){

      const DataList  = await GetAllDocs('transporter_list')


      setTransporterList(DataList);
    };

    async function GetAccount(){
      const Acc = await CURRENT_ACC()
      setAccount(Acc.id)
    }

    function changeHandler (val,type){
      setValues( prevValues => {
        return { ...prevValues,[type]: val}
      })
    }

    function changeHandler2 (val,type){
      setDriver( prevValues => {
        return { ...prevValues,[type]: val}
      })
    }

    function ShopeeFormat(data){
      var regex = /\b['6']\d{8,12}/
      let output = data.match(regex)
      if(!output || !output.length){ return null }
      const Mobile = output && ValidatePhone(output[0],'mobile')
      const AfterMobile = data.split(output[0]).pop().trim();
      const BeforeMobile = data.split(output[0]).shift().replaceAll(',', '').trim()
      return {'name':BeforeMobile,'mobile':Mobile,'address':AfterMobile}
    }


    


    async function Validate(){
      setIsloading(true)
      const Mobile = ValidatePhone(driver.mobile,'mobile')
      const ValidField = Validation(['address']);

      if(Mobile && ValidField){
          driver['mobile'] = Mobile

          const Shopee = ShopeeFormat(values.address)

          const Result = await UpdateDriver(values,driver,orderType,Shopee,modal)
          if(Result){
            update(Result)
            ClearState()
          }
      }
      setIsloading(false)

    }


    function ValidatePhone(data,change) {
      const Input = ValidMobile(data);
      if(Input){
        changeHandler2(Input,change)
        return Input
      }else{
        changeHandler2({error:"Invalid Value"},change)
        return null;
      }
    }

    function UpdateTransporter(data){
      const NewArray = [...transporterList]
      const found = NewArray.some(el => el.id === data.id);
      if (!found) NewArray.push(data);
      setTransporterList(NewArray)
    }

    function Validation(obj){
      var validated = true
      obj.map((name)=>{
          if(!values[name] || values[name].error){
              setValues((prevState) => ({ ...prevState, [name]: {error:"Required"} }));
              validated = false
          }
      })
      return validated
    }


    function ClearState(){
      setIsloading(false)
      clear(null)
    }

    async function VerifyRemove(){
      if (confirm('Are you sure you want to cancel delivery ?')) {
        RemoveOrder()
      } else {
        console.log('Cancel Delete');
      }
  }

  async function RemoveOrder(){
    setIsloading(true)
    const status = await DeleteDelivery(data,orderType)
    if(status){
      if(modal == 'order'){
        update(status)
      }
      if(modal == 'delivery'){
        cancel(status.id)
      }
      ClearState()
    }else{
      console.log("Error Remove")
    }
    setIsloading(false)
  }




    function SetDriver(data){
      data['id'] = data.driver_id
      delete data.driver_id
      setDriver(data);
    }


    const CatCol = () => {

      const Arr = [
          [{name:'name',display:'Name'}],
          [{name:'name',display:'Name',content:<SuggestList data={transporterList} values={driver} set={(val)=>SetDriver(val)}/>}],
          [{name:'mobile',display:'Driver Mobile',mobileno:true}],
      ]

      if(driver && driver.id) return Selected(driver)
      return (
          <Form 
                      data={Arr}
                      value={driver}
                      set={setDriver}
          />
      )
  }

  const Selected = (data) => {
      const {name,mobile,bank} = data
      return (
          <View style={styles.selected}>
              <View style={{flex:1}}>
                      <Text>{name}</Text>
                      <Text>{mobile}</Text>
                      <Text>{bank}</Text>
              </View>
              <TouchableOpacity style={{padding:5}} onPress={()=>setDriver({})}>
                      <Text>X</Text>
              </TouchableOpacity>
          </View>
      )
  }

  const StatusList = () => {
    if(modal == 'order' || data.status == 'delivered') return null
    return (
        <Form 
            data={[
                    [{name:'status',display:'Status',flex:1,listdown:['pending','pickup','delivered']}],
                  ]}
            value={values}
            set={setValues}
        />      
    )
  }

  const ButtonRow = () => {
    return (
      <View style={{flexDirection:'row',flexWrap:'wrap',marginTop:10}}>
          {DelBtn()}
          <View style={{flex:1}}/>
          <Button title={'UPDATE'} isloading={isLoading} icon={'md-add-circle'} onPress={() => Validate()}/>
      </View>
    )
}

const DelBtn = () => {
  if(!driver.id || data.proof) return null
  return <Button isloading={isLoading} color={'red'} icon={'md-trash'} onPress={() => VerifyRemove()}/>
}


    if(!data || !account) return null


    const Proof = data.proof?false:true
    const MobileDetails = modal == 'delivery' ? 'customer_mobile' : 'mobile'

        return (
                <Modal title={!Proof?'Completed':'Add Driver'} icon={'truck'}  Iconfont={'MaterialCommunityIcons'} visible={data?true:false} setVisible={()=>ClearState()}>
                        <Text style={{fontSize:18}}>Order ID : {data.id}</Text>
                        {CatCol()}
                        <Form 
                                data={[
                                            ['BANK'],
                                            [{name:'bank',display:'Bank',listdown:BankLIST},{name:'account',display:"Account"},{name:'customer_id',display:'SSM/MYKAD'}],
                                        ]}
                                value={driver}
                                set={setDriver}
                            />

                          <Form 
                                data={[
                                            [{name:'address',display:'Address'}],
                                            [{name:MobileDetails,display:'Customer Mobile'}],
                                            [{name:'delivery_remark',display:'Remark'}],
                                        ]}
                                value={values}
                                set={setValues}
                          />
                      
                        <View style={{paddingVertical:10}}>
                        </View>
                        <DOLINK data={data} account={account} type={orderType} modal={modal}/>
                        <PROOF data={data}/>
                        <Progress data={data}/> 
                        <StatusList/>      
                        <ButtonRow/>
                </Modal>
        )

    

}

const styles = StyleSheet.create({
    container:{
      padding:10,
      flexDirection:'row',
      flexGrow:1,
      borderBottomWidth:1,
      borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    btn:{
        flexDirection:'row',
        alignContent:'center',
        padding:2,
        marginHorizontal:5,
        alignItems:'center',
        justifyContent:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      orderSelect:{
        borderRadius:5,
        padding:5,
        borderColor:'#aaa',
        flexDirection:'row',
        backgroundColor:'#eee'
      },
      input2:{
        width:50,
      },
      deliverybtn:{
        padding:10,
        alignContent:'center',
        alignItems:'center'
      },
      deliverytxt:{
        color:COLORS.Link,
        fontSize:14
      },
      link:{
        color:COLORS.Link,
        fontSize:16,
        marginLeft:2
      },
      selected:{
        padding:5,
        borderWidth:1,
        borderRadius:5,
        borderColor:'#aaa',
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
        backgroundColor:'#eee'
    }
  });
  