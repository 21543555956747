import React from "react";
import { Page, Text, Image, Document, StyleSheet,View ,Font} from "@react-pdf/renderer";
import moment from 'moment';
import Helvetica from "../../../assets/fonts/Helvetica.ttf";
import Helveticabold from "../../../assets/fonts/Helvetica-Bold.ttf";

export default function PdfQuotation(props) {

  const {user,details} = props


  Font.register({ family: 'Helvetica-bold', src: Helveticabold });
  Font.register({ family: 'Helvetica', src: Helvetica });


  const Address = () => {
    return  (
      <View style={styles.address}>
              <Text style={[styles.boldtext,{fontSize:11}]}>{user.name}</Text>
              <Text style={styles.stext}>({user.ssm})</Text>
              <View style={{flexDirection:'row',flexShrink: 1,textAlign:'left',width:122}}> 
                <Text style={[styles.text,{flex: 1,textAlign:'left'}]}>{user.address}</Text>
              </View>
              {Additional('website',user.website)}
              {Additional('Tel',user.tel)}
      </View>
    )
  }

  const Additional = (val,data) => {
    if(!data) return null
    return <Text style={styles.text}>{val} : {data}</Text>
  }


  function inWords (num) {

    const  a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
    const  b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

          var number = parseFloat(num).toFixed(2).split(".");
          var num = parseInt(number[0]);
          var digit = parseInt(number[1]);

        if ((num = num.toString()).length > 12) return 'overflow';
        var n = ('00000000000' + num).substr(-12).match(/^(\d{3})(\d{3})(\d{3})(\d{1})(\d{2})$/);
        var d = ('00' + digit).substr(-2).match(/^(\d{2})$/);;
        if (!n) return; var str = '';
        str += (n[1] != 0) ? (Number(n[1]) > 99 ? a[Number(n[1][0])] + 'hundred ' : '') + (a[Number(n[1])] || b[n[1][1]] + ' ' + a[n[1][2]]) + 'billion ' : '';
        str += (n[2] != 0) ? (Number(n[2]) > 99 ? a[Number(n[2][0])] + 'hundred ' : '') + (a[Number(n[2])] || b[n[2][1]] + ' ' + a[n[2][2]]) + 'million ' : '';
        str += (n[3] != 0) ? (Number(n[3]) > 99 ? a[Number(n[3][0])] + 'hundred ' : '') + (a[Number(n[3])] || b[n[3][1]] + ' ' + a[n[3][2]]) + 'thousand ' : '';
        str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
        str += (Number(n[5]) !== 0) ? ((str !== '') ? '' : '') +
                    (a[Number(n[5])] || b[n[5][0]] + ' ' +
                        a[n[5][1]]) + '' : '';
        str += (d[1] != 0) ? ((str != '' ) ? "and " : '') + (a[Number(d[1])] || b[d[1][0]] + ' ' + a[d[1][1]]) + 'Cents Only!' : 'Only!';
        return 'MALAYSIA RINGGIT '+str.toUpperCase();
  }

  const AddressBox = () => {
    return(
      <View style={{flex:1,marginRight:10}}>
        <Text style={styles.text}>TO :</Text>
        <Text style={styles.text}>{details.company}</Text>
        <Text style={styles.text}>{details.address}</Text>
        <Text style={styles.text}>Attn : {details.name}</Text>
      </View>
    )
  }

  const Information = () => {
    if(!details.remark) return null
    return(
      <View style={{paddingVertical:10}}>
        <Text style={styles.boldtext}>{'Additional Information'}</Text>
        <Text style={styles.text}>{details.remark}</Text>
      </View>
    )
  }

  const Cancel = () => {
    if(details.status !== 'cancel') return null
    return(
      <View style={styles.cancelerr}>
        <Text style={[styles.boldtext,{fontSize:22,color:'red'}]}>{'CANCELLED'}</Text>
      </View>
    )
  }


  const BillDetails = () => {
    return(
      <View style={{flexDirection:'row',marginRight:10}}>
        <View style={{marginRight:15}}>
          <Text style={styles.text}>Credit Note</Text>
          <Text style={styles.text}>Date</Text>
          <Text style={styles.text}>Issue By</Text>
          <Text style={styles.text}>Invoice ID</Text>
        </View>
        <View>
          <Text style={styles.boldtext}>: {'CR-'+user.code +' '+ details.id}</Text>
          <Text style={styles.text}>: {ReformatTime(details.updated?details.updated:details.createdAt,'DD/MM/YYYY')}</Text>
          <Text style={styles.text}>: {user.pic}</Text>
          <Text style={styles.text}>: {user.code +'-'+ details.invoice_id}</Text>
        </View>
      </View>
    )
  }

  const TableTh = () => {
    return(
      <View style={{flexDirection:'row',borderBottomWidth:0.5,borderTopWidth:0.5,paddingVertical:5}}>
        <View style={{flex:6}}><Text style={styles.text}>DESCRIPTION</Text></View>
        <View style={[styles.center,{flex:2}]}><Text style={styles.text}>QTY</Text></View>
        <View style={{flex:2}}><Text style={styles.text}>U.PRICE</Text></View>
        <View style={{flex:2}}><Text style={styles.text}>TOTAL</Text></View>
      </View>
    )
  }


  const ListItem = (data) => {
    return(
        <View style={{paddingVertical:5}}>
            {data.map((item,key) => {

              const Amount  = parseFloat(item.amount)
              const Qty  = parseInt(item.qty)

              return (
                <View key={key} style={{flexDirection:'row',marginBottom:5}}>
                  <View style={{flex:6}}><Text style={styles.text}>{item.name}</Text></View>
                  <View style={[styles.center,{flex:2}]}><Text style={styles.text}>{item.qty}</Text></View>
                  <View style={{flex:2}}><Text style={styles.text}>RM{Amount.toFixed(2)}</Text></View>
                  <View style={{flex:2}}><Text style={styles.text}>RM{(Qty*Amount).toFixed(2)}</Text></View>
                </View>
              ); 
            })}
        </View>
    )
}




const BillSum = () => {

  const CreditUsed = details.paid ? details.paid.reduce((n, {amount}) => n + parseFloat(amount), 0) : 0;

  return(
    <View style={{flexDirection:'row',marginLeft:20}}>
      <View style={{marginRight:15}}>
        <Text style={styles.text}>SUBTOTAL</Text>
        <Text style={styles.text}>TAX</Text>
        <Text style={styles.text}>ROUNDING ADJ</Text>
        <Text style={[styles.text]}>NETT TOTAL</Text>
        <Text style={styles.text}>AMOUNT USED</Text>
        <Text style={[styles.text,{marginTop:10,paddingVertical:5,}]}>BALANCE</Text>
      </View>
      <View>
        <Text style={styles.text}>: {details.amount}</Text>
        <Text style={styles.text}>: 0.00</Text>
        <Text style={styles.text}>: 0.00</Text>
        <Text style={[styles.text]}>: {details.amount}</Text>
        <Text style={styles.text}>: {CreditUsed.toFixed(2)}</Text>
        <View style={styles.pricebx}>
            <Text style={[styles.boldtext,{fontSize:11}]}> RM{(details.amount - CreditUsed).toFixed(2)}</Text>
        </View>
      </View>
    </View>
  )
}

const Terms = () => {
  return (
    <View style={{flexDirection:'row',marginTop:5}}>
      <View>
        <Text style={styles.stext}>Note : </Text>
      </View>
      <View style={{flex:1}}>
        <Text style={styles.stext}>1. All credit note are valid for one (1) year from the date of the credit note. If acceptance is received after this period has lapsed, the item may be required to be forfeit.</Text>
      </View>
    </View>
  )
}

const ReformatTime = (data,format) => {
  if(!data) return null
  return moment(data.toDate()).format(format);
}

  return (
    <Document>
      <Page size="A4" style={styles.body}>

          <View style={styles.header} fixed>
            <Image style={styles.image}  source={{ uri: user.logo}} />
            <View style={{flex:1}}/>
            {Address()}
          </View>

          <View style={[styles.center,{marginBottom:20}]}><Text style={{fontSize:12}}>CREDIT NOTE</Text></View>

          <View style={{flexDirection:'row',marginBottom:5}}>
            {AddressBox()}
            {BillDetails()}
          </View>
          {Information()}

          {TableTh()}
          <View style={{borderBottomWidth:0.5,paddingBottom:10,minHeight:250}}>
              {ListItem(details.products)}
              {Cancel()}
          </View>

          <View style={{flexDirection:'row',marginTop:15}}>
            <View style={{flex:1}}>
              <Text style={styles.text}>{inWords(details.amount)}</Text>
              {Terms()}
            </View>
            {BillSum()}
          </View>

          <View style={{flexDirection:'row',marginTop:20}}>
              <Text style={styles.stext}>This is computer generated credit note,no signature required.</Text>
              <Text style={[styles.stext,{marginLeft:20}]}>{ReformatTime(details.createdAt,'YYYY-MM-DD hh:mm:ss')}</Text>
          </View>
          {details.updated && <Text style={[styles.stext,{marginLeft:20}]}>updated : {ReformatTime(details.updated,'YYYY-MM-DD hh:mm:ss')}</Text>}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};


const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    width:'50%'
  },
  address:{
    alignItems:'flex-start',
  },
  text: {
    fontSize: 10,
    fontFamily: "Helvetica",
    color:'#111',
    marginBottom:3
  },
  stext:{
    fontSize: 9,
    fontFamily: "Helvetica",
    color:'#111',
    marginBottom:2
  },
  boldtext:{
    fontSize: 10,
    fontFamily: "Helvetica-bold",
    color:'#111',
  },
  btext: {
    fontSize: 12,
    fontWeight:'200',
    fontFamily: "Helvetica",
    color:'#111',
  },
  pricebx:{
    borderTopWidth:0.5,
    borderBottomWidth:0.5,
    paddingVertical:5,
    marginTop:10
  },
  image: {
    width:100,
    height:100,
  },
  cancelerr: {
    padding:5,
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center'
  },
  header: {
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center'
  },
  company:{
    flexDirection:'row',
    alignItems:'baseline'
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "#777",
  },
  center:{
    alignContent:'center',
    alignItems:'center',
    flexDirection:'row',
    justifyContent:'center',
    textAlign:'center'
  }
});
