import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator} from 'react-native';
import moment from 'moment';
import { PDFDownloadLink} from "@react-pdf/renderer";
import {Button} from '../../../assets/component';
import PDFile from './pdf';
import AddCreditNote from '../Order/add';


export default function Table(props) {

  const {tab,setList,list,next,loading,user,del} = props


  const [option, setOption] = useState(null);
  const [modified, setModified] = useState(null);


  useEffect(() => {
    
    setOption(null)
  }, [tab]);


  const Th = (title,size) => {
    return(
        <View style={{flex:size}}>
            <Text style={styles.item}>{title}</Text>
        </View>

    )
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          {Th('ID',1)}
          {Th('Name',3)}
          {Th('Amount',2)}
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }


    function VerifyRemove(data){
        if (confirm('Are you sure you want to confirm ?')) {
          del(data)
        } else {
          console.log('Cancel Delete');
        }
    }

    const DeleteBtn = (data) => {
      if(tab !== 'pending') return null
      return <Button icon={'trash'} disable={loading} style={{marginRight:5}} onPress={()=>VerifyRemove(data)} color={loading?'#aaa':'#1b3443'} />
    }


    const ExtraDetails = (props) => {
      const {data}= props
        if(option !== data.id) return null
       return (
            <View style={{flexDirection:'row'}}>
                  {Print(data)}
                  {UpdateBtn(data)}
                  {DeleteBtn(data)}
            </View>
       )
     }

     const UpdateBtn = (data) => {
      if(tab !== 'pending') return null

      return (
        <Button onPress={()=>setModified(data)} icon={'ios-pencil'} style={{marginRight:10}} color={'#1b3443'}/>
      )
     }


     const Print = (item) => {
      if(!user) return null
      return (
        <PDFDownloadLink document={<PDFile details={item} user={user}/>} fileName={'CN_'+item.id}>
          {({loading})=>
            <Button icon={'print'} disable={loading} style={{marginRight:5}} color={loading?'#aaa':'#1b3443'} />
          }
        </PDFDownloadLink>
      )
    }







    const RenderItem = ({item,index}) => {
      return(
        <TouchableOpacity onPress={() => setOption(option == item.id?null:item.id)}  style={{padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }}>
          <View style={{flexDirection:'row'}}>
                  <View style={{flex:1}}><Text style={styles.id}>#{item.id}</Text></View>
                  <View style={{flex:3}}>
                        <Text style={styles.title}>{item.company?item.company:item.name}</Text>
                        <Text style={[styles.item,{fontSize:12,color:'#de6d6d'}]}>{item.remark}</Text>
                        <Text style={[styles.item,{fontWeight:'200',fontSize:12}]}>{ReformatTime(item.updated)}</Text>
                  </View>
                  <View style={{flex:2}}><Text style={styles.item}>RM{parseFloat(item.amount).toFixed(2)}</Text></View>
          </View>
                <ExtraDetails data={item}/>
        </TouchableOpacity>
      )
  }

  const ReformatTime = (data) => {
    if(!data) return null
    return moment(data.toDate()).format('YYYY-MM-DD hh:mm:ss');
  }


  function UpdateData(data){
    const dataArray = [ ...list[tab]]
    const result = dataArray.map((obj) => obj.id === data.id ? data : obj);
    setList({...list, [tab]:result})
  }



    

    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list[tab]}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
            onEndReached={next}
          />
          <AddCreditNote add={(val)=>UpdateData(val)} visible={modified?true:false} setvisible={setModified} data={modified} update creditnote/>
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  id:{
    marginRight:5,
    color:'#006ad4',
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    position:'absolute',
    backgroundColor:'#fff',
    zIndex:1,
    left:-90,
    borderRadius:5,
    padding:10,
    paddingHorizontal:20,
    shadowOffset: { width: 2, height: 4 },
        shadowRadius: 10,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  reorder:{
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
    margin:10
  }
});
