import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator } from 'react-native';
import moment from 'moment';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import COLORS from "../../../../assets/component/colors";
import {Button,Form} from "../../../../assets/component";
import {AddDebit,updateImageUrl} from "../../../../assets/js/firebase";
import {UploadData} from "../../../../assets/js/upload";
import PendingList from './debit/listpending';
import Box from "./debit/box";
import CreditNote from "./debit/creditnote";


export default function DebitsModal(props) {

    const {success} = props

    const [isLoading, setIsloading] = useState(false);
    const [values,setValues] = useState({'order':[],'amount':0,'balance':0,'payment':'bankin','dated':''})
    const [creditnote, setCreditNote] = useState(null);


    const [showPending, setShowPending] = useState(true);




    function changeHandler (val,type){
        setValues( prevValues => {
          return { ...prevValues,[type]: val}
        })
      }




async function Validate(){
    const {order,others,amount,payment,dated} = values
    var valid = true;

    if(!dated || dated.error){
        valid=false
    }

    if(amount <= 0 || isNaN(amount) || amount.error){
        changeHandler({error:"Amount must not be 0"},'amount')
        valid=false
    }


    if(!order){
        changeHandler({error:"Choose order"},'order')
        valid=false
    }

    if(order == 'others' && !others){
        changeHandler({error:"No name"},'order')
        valid=false
    }
    var CreditBalance = 0;
    
    if(creditnote){
        CreditBalance = creditnote.paid ? (creditnote.amount - creditnote.paid.reduce((n, {amount}) => n + amount, 0)) : creditnote.amount;
        if(CreditBalance - parseFloat(amount) < 0){
            alert("Credit Not sufficent reduce the amount")
            valid=false
        }
    }


    if(valid){
        setIsloading(true)
        const NAME = order === 'others'?others:order.company?order.company:order.name
        const Newval = {
            'orders':order.length?order:null,
            'name':NAME,
            'amount':amount,
            'status':'active',
            'payment_method':payment,
            'date':dated,
        }

        if(creditnote){
            Newval['credit_note'] = creditnote.id
        }

        const gEtDoc = await AddDebit(Newval,CreditBalance);
        if(values.file){
            const DownloadUrl = await UploadData(gEtDoc.id,values.file,'debits') 
            if(DownloadUrl){
                setIsloading(false)
                const YearDir = moment(gEtDoc.date.toDate()).format('YYYY');
                updateImageUrl('debits',YearDir,gEtDoc.id,DownloadUrl)
                gEtDoc['image'] = DownloadUrl
                success(gEtDoc)
            }
        }else{
            setIsloading(false)
            success(gEtDoc)
        }
    }

}

function ChangeAmt(index,val){

        const NewOrder = [...values.order]
        NewOrder[index]['paying'] = val
        const sum = NewOrder.reduce((acc, o) => acc + parseFloat(o.paying), 0)
        changeHandler(sum,'balance')
        changeHandler(sum,'amount')
        changeHandler(NewOrder,'order')
        

}


    
    const OrderSelect = (val) => {
        if(!val) return null
        if(val == 'others'){
            return (
            <Form 
                data={
                            [
                                [{name:'others',display:'Others',flex:1}]
                            ]
                    }
                value={values}
                set={setValues}
            />
            )
        }
        return (
            <View>
                <TouchableOpacity onPress={()=>{changeHandler([],'order');changeHandler(0,'amount'),changeHandler(0,'balance')}}>
                    <Ionicons name='close' size={18} color={COLORS.Error} />
                </TouchableOpacity>
                {val.map((i,key)=>{
                    const Name = i.company?i.company:i.name
                    const Balance = parseFloat(i.amount)-parseFloat(i.paid)
                    return <Box data={i} change={(val)=>ChangeAmt(key,val)} key={i}/>
                })}
                <TouchableOpacity style={styles.morebtn} onPress={()=>{setShowPending(true)}}>
                    <Ionicons name='add' size={18} color={'#aaa'} />
                    <Text>Add More</Text>
                </TouchableOpacity>
            </View>
        )
    }

    function OrderChoose(item){
        if(item == 'others'){
            changeHandler('others','order')
            changeHandler(0,'balance')
            changeHandler(0,'amount')
        }else{
            const found = values.order.some(el => el.id === item.id);
            if(!found){
                const Paid = item.paid?parseFloat(item.paid):0
                var Amt = parseFloat(item.amount)-Paid
                item['paying'] = Amt
                changeHandler([item,...values.order],'order')
                changeHandler(values.amount+Amt,'balance')
                changeHandler(values.amount+Amt,'amount')
            }
        }
        setShowPending(false)
    }



    const Footer = () => {
        const Disable =  values.payment == 'credit note' && !creditnote ? true : false
        return  <Button title={'ADD'} isloading={isLoading} icon={'md-add-circle'} onPress={() => Validate()} color={'#426ff5'} disable={Disable}/>
    }



    if(showPending || !values.order) return <PendingList changeHandler={changeHandler} OrderChoose={OrderChoose}/>

    return(
        <View>
                {OrderSelect(values.order)}

                <Form 
                    data={
                                [
                                    [{name:'amount',display:'Amount',flex:1},{name:'dated',display:'Date',date:true}],
                                    [{name:'payment',display:'Payment',flex:1,listdown:['bankin','cash','credit note']}],
                                    [{name:'file',display:'File',file:true,size:25}],
                                ]
                        }
                    value={values}
                    set={setValues}
                />
                <CreditNote values={values} set={setValues} credit={creditnote} setcredit={setCreditNote} amount={values.amount}/>


                <Footer/>
        </View>
    )




}

const styles = StyleSheet.create({
    container:{
        padding:5,
        flexDirection:'row',
        backgroundColor:'#eee'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },

    orderSelect:{
        flexDirection:'row',
        borderWidth:1,
        borderRadius:4,
        paddingHorizontal:5,
        borderColor:'#ddd',
        flex:1,
        marginBottom:4,
    },
    item:{
        fontSize:14
    },
    morebtn:{
        marginVertical:4,
        borderRadius:5,
        borderWidth:1,
        borderColor:'#aaa',
        alignContent:'center',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:'#eee',
        padding:4,
        flexDirection:'row'
    }
});
