import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Linking} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import moment from 'moment';
import {Copy} from '../../../../assets/component';
import COLORS from '../../../../assets/component/colors';


export default function Table(props) {

  const {list,loading,navSubcat,sub,edit,hide} = props




const Th = (title,size) => {
  return(
      <View style={{flex:size}}>
        <TouchableOpacity style={styles.flexcenter} >
          <Text style={styles.th}>{title}</Text>
        </TouchableOpacity>
      </View>

  )
}


    const Header = () => {
      if(hide) return null
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          {Th('ID',1)}
          {Th('NAME',2)}
          {!sub?Th('SUB CATEGORY',2):null}
          {Th('FILE',2)}
          {Th('AMOUNT',2)}
          <View style={{width:40}}/>
        </View>
      )
    }

    const EditBtn = (item) => {
      if(!item.category.id) return null
      return (
        <TouchableOpacity onPress={()=>{edit(item)}} style={{paddingHorizontal:5}}>
              <Ionicons name='ios-pencil' size={18} color={'#777'} />
        </TouchableOpacity>
      )
    }

    const ImageLink = (image) => {
      if(!image) return null
      return (
        <View style={{flexDirection:'row'}}>
              <View>
                  <TouchableOpacity style={[styles.option]} onPress={() => Linking.openURL(image)}>
                      <Ionicons name="ios-image-outline" size={24} color='#00b0a4' />
                      <Text>LINK</Text>
                  </TouchableOpacity>
                  <Copy link={image} title={'copy'}/>
              </View>
        </View>
      )
    }


    const RenderItem = ({item,index}) => {
      const Item = (data,string) => {
        return <View style={{flex:2}}><Text style={styles.item}>{string?data:'RM'+data}</Text></View>
      }

      return(
        <View style={{padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#eedcff" }}>
              <View style={{flexDirection:'row',flex:1}}>
                <View style={{flex:1}}><Text style={styles.item}>#{item.id}</Text></View>
                <View style={{flex:2}}>
                  <Text style={styles.item}>{item.category.name}</Text>
                  <Text style={styles.item}>{ReformatTime(item.date)}</Text>
                  <Text style={styles.created}>{ReformatTime(item.createdAt)}</Text>
                </View>
                {SubCategory(item)}
                <View style={{flex:2}}>{ImageLink(item.image)}</View>
                {Item(item.amount)}
                <View style={[styles.flexcenter,{width:40}]}>{EditBtn(item)}</View>
              </View>
        </View>

      )
  }

  const SubCategory = (item) => {

    const ItemName = item.category.sub_category

    if(sub) return null
    if(!ItemName) return <View style={{flex:2}}/>
    return (
      <TouchableOpacity style={{flex:2}} onPress={()=>navSubcat(ItemName)}><Text style={styles.itemLink}>{ItemName}</Text></TouchableOpacity>
    )
  }

  const Loader = () => {
    return (
      <View style={{padding:10}}>
        <ActivityIndicator size={'small'}/>
      </View>
    )
  }

  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD-MM-YYYY');
    return format
}


    if(loading) return <Loader/>

    return(
      <View style={styles.container}>
          {Header()}
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list}
            renderItem={RenderItem}
          />   
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  th:{
    marginRight:5,
    fontWeight:'600',
    color:'#5700a9',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  itemLink:{
    marginRight:5,
    color:COLORS.Link,
  },
  desc:{
    color:'#777',
    fontWeight:'400'
  },
  qty:{
    fontWeight:'bold',
    color:'#e07c00',
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    borderRadius:5,
    padding:2,
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
    backgroundColor:'#fff',
    paddingHorizontal:10,
    marginRight:5,
    shadowOffset: { width: 1, height: 1 },
        shadowRadius: 3,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  created:{
    fontSize:12,
    color:'#23c9d8'
  }
});
