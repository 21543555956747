import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,ActivityIndicator,Text} from 'react-native';
import moment from 'moment';
import {Form,Modal,Button} from "../../../../assets/component";
import {UploadData} from "../../../../assets/js/upload";
import {FormatDate} from "../../../../assets/js/format";
import {updateImageUrl,UpdateCredit,deleteCredit,AddCredit,AddDebit,deleteDebit,UpdateDebit} from "../../../../assets/js/firebase";


export default function Update(props) {

    const {data,clear,update,tab} = props

    const [values, setValues] = useState(null);
    const [isLoading, setIsloading] = useState(false);


    useEffect(() => { 
        if(data){
            const Da = {...data,date:FormatDate(data.date,'DD-MM-YYYY')}
            setValues(Da)
        }
    }, [data]);





    async function ValCredit(data){


        const valid = Validation(['date']);
        const validNum = ValidationNum(['amount']);
        if(valid && validNum){

            setIsloading(true)

            var Increment = (parseFloat(values.amount) - parseFloat(data.amount))
            const YearDir = moment(data.date.toDate()).format('YYYY')
            const yearNow = moment(values.date,'DD-MM-YYYY').format('YYYY')
            

            if(YearDir != yearNow){
                await deleteCredit(data) 
                data['amount'] = values.amount
                data['date'] = values.date
                await AddCredit(data)
            }else{
                data['amount'] = values.amount
                data['date'] = values.date
                data['remark'] = values.remark
                await UpdateCredit(data,Increment)
            }

        
            if(values.image && data.image !== values.image) {
                const DownloadUrl = await UploadData(data.id,values.image,'credits') 
                if(DownloadUrl){
                    setIsloading(false)
                    updateImageUrl('credits',yearNow,data.id,DownloadUrl)
                    data['image'] = DownloadUrl
                    Success(data)
                }
                
            }else{
                Success(data)
            }
        }

    }


    async function Validate2(data){


        const valid = Validation(['date']);
        const validNum = ValidationNum(['amount']);
        if(valid && validNum){

            setIsloading(true)

            var Increment = (parseFloat(values.amount) - parseFloat(data.amount))
            const YearDir = moment(data.date.toDate()).format('YYYY')
            const yearNow = moment(values.date,'DD-MM-YYYY').format('YYYY')
            

            if(YearDir != yearNow){
                await deleteDebit(data) 
                data['date'] = values.date
                await AddDebit(data)
            }else{
                data['date'] = values.date
                await UpdateDebit(data,Increment)
            }

        
            if(values.image && data.image !== values.image) {
                const DownloadUrl = await UploadData(data.id,values.image,'credits') 
                if(DownloadUrl){
                    setIsloading(false)
                    updateImageUrl('credits',yearNow,data.id,DownloadUrl)
                    data['image'] = DownloadUrl
                    Success(data)
                }
                
            }else{
                Success(data)
            }
        }

    }

    
    function Validation(obj){
        var validated = true
        obj.map((name)=>{
            if(!values[name] || values[name].error){
                setValues((prevState) => ({ ...prevState, [name]: {error:"Required"} }));
                validated = false
            }
        })
        return validated
    }

    function ValidationNum(obj){
        var validated = true
        obj.map((name)=>{
            if(values[name] <= 0 || isNaN(values[name]) || values[name].error){
                setValues((prevState) => ({ ...prevState, [name]: {error:"Only Integer Value"} }));
                validated = false
            }
        })
        return validated
    }

 
    function Success(data){
        setIsloading(false)
        update(data)
        setValues(null)
        clear()
    }




    const CreditUpdate  = () => {
        const editable = values && values.creditnote?false:true
            return (
                <Form 
                    data={
                                [
                                    [{name:'image',display:'File',file:true,size:65}],
                                    [{name:'amount',display:'Amount',flex:1,'editable':editable}],
                                    [{name:'remark',display:'Remark'}],
                                    [{name:'date',display:'Date',date:true}],
                                ]
                        }
                    value={values}
                    set={setValues}
                />
        )
    }


    const DebitUpdate  = () => {
        return (
            <Form 
                data={
                            [
                                [{name:'date',display:'Date',date:true}],
                            ]
                    }
                value={values}
                set={setValues}
             />
        )
    }

    if(!data) return null
    

    const Title = tab == 'credit' ? data.category.name : data.name
    
    return(
        <Modal title={Title} visible={data?true:false} setVisible={clear}>
            {tab == 'credit' ? CreditUpdate():DebitUpdate()}
            <Button title={'UPDATE'} color={'#056a7a'} icon={'md-add-circle'} onPress={() => tab == 'credit'?ValCredit(data):Validate2(data)} isloading={isLoading}/>
        </Modal>
    )

}

const styles = StyleSheet.create({
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
  });
  