import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View,Text, ActivityIndicator,TouchableOpacity} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import {GetAllDocs,GetItemDetails,GetDocsByids } from "../../../../../assets/js/firebase";
import moment from 'moment';
import SearchBox from "../../../../../assets/component/searchbox";
import {YearSelector} from '../../../../../assets/component/momentselect';
import Tab from './tab';
import Table from './table';


export default function StaffSalaryScreen({route,navigation}) {
  const {pid} = route.params;

  const isMounted = useRef();

  const DafaultTab = {paid:[],pending:[]}

  const [year, setYear] = useState(moment().format("YYYY"));
  const [item, setItem] = useState(null);
  const [list, setList] = useState(DafaultTab);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);
  const [isLoading, setIsloading] = useState(false);




  useEffect(() => {
    GetStaff()
   }, []);

  useEffect(() => {
    if(year){
      setIsloading(true)
      getSalary(year);
    }

  }, [year]);


  async function getSalary(year){
    var Mths = []
    for (var i = 1; i < 13; i++) {
      Mths.push((i < 10 && '0') + i +'-'+year);
    }
    const SalaryList = await GetDocsByids('staff/'+pid+'/salary',Mths)
    setList({'paid':Filter(SalaryList,'paid'),'pending':Filter(SalaryList,'pending')});
    setIsloading(false)
  };

  function Filter(arr,status){
    return arr.filter(obj => obj.status === status);
  }




  async function GetStaff() {
    const ProductsDetail = await GetItemDetails(pid,'staff')
    if(ProductsDetail){
      setItem(ProductsDetail)
    }
    
  }



  function StockNav(data){
    navigation.navigate('stock', {pid:data.id})
  }

  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD-MM-YYYY HH:MM A');
    return format
}

const BackBtn = () => {
  return (
    <TouchableOpacity style={styles.backbtn} onPress={()=>navigation.goBack()}>
      <Ionicons name={'chevron-back-outline'} size={28} color='#4a8eb5' />
      <Text>BACK</Text>
    </TouchableOpacity>

  )
}





if(!item){
  return <View style={styles.center}><Text>Item not found</Text></View>
}

const Details = () => {
  return (
    <View style={{flex:1}}>
        <Text>Name : {item.name}</Text>
        <Text>Mykad : {item.mykad}</Text>
        <Text>Position : {item.position}</Text>
        <Text>Bank : {item.bank}</Text>
        <Text>Acc no : {item.account}</Text>
        <Text>Created At : {ReformatTime(item.createdAt)}</Text>
    </View>
  )
}




  return (
    <View style={styles.container}>
      <BackBtn/>
      <View style={{flexDirection:'row',zIndex:99}}>
          <Details/>
          <YearSelector setYear={setYear} year={year}/>
      </View>
      <View style={styles.tabBx}>
        <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab} list={list}/>

        <View style={{flex:1,borderBottomWidth:0.5,borderColor:'#ccc'}}/>
        <SearchBox set={(val)=>setList({...list, [tabactive]:val})} products={list[tabactive]} filters={['id']}/>
      </View>
      <Table 
        tab={tabactive} 
        setList={setList} 
        list={list} 
        staff={item}
        loading={isLoading}
        nav={StockNav}
      />


    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
  center:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',
    flex:1
  },
  backbtn:{
    borderRadius:5,
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
  }
});
