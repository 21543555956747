import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View,Text, ActivityIndicator,TouchableOpacity} from 'react-native';
import {ApprovedVoucher,GetOrders,RejectPaymentVoucher,ApprovedClaims} from "../../../assets/js/firebase";
import moment from 'moment';
import Ionicons from '@expo/vector-icons/Ionicons';
import SearchBox from "../../../assets/component/searchbox";
import Button from "../../../assets/component/button";
import Tab from './tab';
import AddVoucher from './add';
import Table from './table';
import { ScrollView } from 'react-native-gesture-handler';


export default function PaymentVoucherList({route,navigation}) {

  const LimitPerload = 12

  const isMounted = useRef();

  const DafaultTab = {pending:[],reject:[],approved:[]}

  const [update, setUpdate] = useState(null);

  const [lastVisible , setLastVisible ] = useState(null);
  const [list, setList] = useState(DafaultTab);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);
  const [isLoading, setIsloading] = useState(true);
  const [statusloading, setStatusloading] = useState(false);
  const [picked, setPicked] = useState([]);


  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      if(list[tabactive].length == 0){
        setIsloading(true)
        getList(tabactive);
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [tabactive]);


  


  async function getList(active){

    const ListOrders = await GetOrders('payment_voucher',active,LimitPerload)

      if(isMounted.current){
        setLastVisible({...lastVisible, [active]:ListOrders.last});
        setList({...list, [active]:ListOrders.list});
        setIsloading(false)
      }
  };

  async function nextList(active,last){

    if(!last[active]) return null

    const ListOrders = await GetOrders('payment_voucher',active,LimitPerload,last[active])

    if(ListOrders){
      console.log("load More")
      setLastVisible({...lastVisible, [active]:ListOrders.last});

      const dataArray = [ ...list[active], ...ListOrders.list]

      setList({...list, [active]:dataArray});
      setIsloading(false)
    }
  
};


  function PushItem(data){
    const dataArray = [ ...list[data.status]]

    const i = dataArray.findIndex(e => e.id === data.id);
    if (i > -1){ 
      dataArray[i] = data
    } else{ 
       dataArray.unshift(data)
    }

    setList({...list, [data.status]:dataArray});

  }

  function StockNav(data){
    navigation.navigate('stock', {pid:data.id})
  }




async function Reject(data){

  if (confirm('Are you sure you want to reject ?')) {
    setStatusloading(true)
    await RejectPaymentVoucher(data)
    UpdateStatus(data)
  } else {
    console.log('Cancel Delete');
  }
}

async function Approve(data){
  setStatusloading(true)
  await ApprovedVoucher(data)
  UpdateStatus(data)
}

function UpdateStatus(data){
    const Pending = list[tabactive].filter(item => !data.some(itemToBeRemoved => itemToBeRemoved.id === item.id))
    setList({...list,['pending']:Pending});
    setPicked([])
    setStatusloading(false)
}

const BackBtn = () => {
  return (
    <TouchableOpacity style={styles.backbtn} onPress={()=>navigation.goBack()}>
      <Ionicons name={'chevron-back-outline'} size={28} color='#4a8eb5' />
      <Text>BACK</Text>
    </TouchableOpacity>

  )
}


const Footer = () => {
  if(!picked.length) return null

  const Sum = picked.reduce((n, {amount}) => n + amount, 0)
  return (
    <View style={{flexDirection:'row',flexWrap:'wrap',padding:10,backgroundColor:'#ccc',position:'absolute',width:'100%',bottom:0}}>
      <View style={{flex:1,minWidth:120}}>
          <View><Text>TOTAL : {picked.length}</Text></View>
          <View><Text>SUM : RM{Sum.toFixed(2)}</Text></View>
      </View>
      <View style={{flexDirection:'row'}}>
      <Button title={'Reject'} onPress={()=> Reject(picked)} color={'#fc3063'} icon={'close-outline'}/>
      <View style={{width:10}}/>
      <Button title={'Approved'} onPress={()=> Approve(picked,Sum)} color={'#4a90ff'} icon={'checkmark'}/>
      </View>
    </View>
  )
}

if(isLoading){
  return (
  <View style={styles.center}><ActivityIndicator size={'small'}/></View>
  )
}




  return (
    <View style={styles.container}>

      <Text style={styles.head}>Payment Voucher</Text>
      <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab} list={list}/>

        <View style={[styles.tabBx]}>
          <AddVoucher add={PushItem} update={update} setupdate={setUpdate}/>
          {!isLoading &&<SearchBox set={(val)=>setList({...list, [tabactive]:val})} products={list[tabactive]} filters={['id']}/>}
        </View>


      <Table 
        tab={tabactive} 
        setList={setList} 
        list={list} 
        next={()=>nextList(tabactive,lastVisible)} 
        loading={isLoading}
        statusloading={statusloading}
        nav={StockNav}
        setPicked={setPicked}
        picked={picked}
        setUpdate={setUpdate}
      />
    <Footer/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap',
    alignItems:'center'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
  center:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',
    flex:1
  },
  backbtn:{
    borderRadius:5,
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
  },
  head:{
    fontSize:22,
    fontWeight:'700'
  },
});
