import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet,View } from 'react-native';
import Tab from './tab';
import Table from './table';
import Types from './types';
import Cat_List from './categorised'
import Footer from './footer'
import {isMobile} from "../../../assets/js/screen";
import {SearchBox} from "../../../assets/component";
import {GetDelivers} from "../../../assets/js/firebase";


export default function ShipmentUpdate({route,navigation}) {


  const MobileScreen = isMobile()

  const LimitPerload = 30

  const isMounted = useRef();
  const DafaultTab = {pending:[],pickup:[],delivered:[]}

  const Cat = ['All','State','Driver']

  const [list, setList] = useState(DafaultTab);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);
  const [isLoading, setIsloading] = useState(false);
  const [lastVisible , setLastVisible ] = useState(null);
  const [picked, setPicked] = useState([]);
  const [statusloading, setStatusloading] = useState(false);
  const [activeType, SetActive] = useState(Cat[0]);


  useEffect(() => {
      isMounted.current = true;
      if (isMounted.current) {
        if(list[tabactive].length == 0){
          setIsloading(true)
          getList(tabactive);
        }
      }
      return () => {
        isMounted.current = false;
      };
    }, [tabactive]);





  async function getList(active){

    var ListOrders = await GetDelivers('delivery',active,LimitPerload)

    if(isMounted.current){
      setLastVisible({...lastVisible, [active]:ListOrders.last});
      setList({...list, [active]:ListOrders.list});
      setIsloading(false)

    }

  };




  async function nextList(active,last){

      if(!last[active]) return null

      const ListOrders = await GetDelivers('delivery',active,LimitPerload,last[active])

      if(ListOrders){
        console.log("load More")
        setLastVisible({...lastVisible, [active]:ListOrders.last});

        const dataArray = [ ...list[active], ...ListOrders.list]
  
        setList({...list, [active]:dataArray});
        setIsloading(false)
      }
    
  };



  



  const DisplayList = () => {


    if(activeType !== 'All'){
      return <Cat_List 
              data={list[tabactive]}
              category={activeType}
              setPicked={setPicked}
              picked={picked}
              tab={tabactive}
            />
    } 
    

    return (
      <Table 
      tab={tabactive} 
      setList={setList} 
      list={list} 
      next={()=>nextList(tabactive,lastVisible)} 
      loading={isLoading}
      setPicked={setPicked}
      statusloading={statusloading}
      picked={picked}
      />
    )

  }
  




  return (
    <View style={styles.container}>
      <View style={styles.tabBx}>
        <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab} />
        <View style={{flex:1}}/>
        <View style={{width:150}}>{!isLoading &&<SearchBox set={(val)=>setList({...list, [tabactive]:val})} products={list[tabactive]} filters={['id','name','delivery']}/>}</View>
        
      </View>
      <Types active={activeType} types={Cat} set={SetActive}/>
      {DisplayList()}
      <Footer picked={picked} setPicked={setPicked} screen={MobileScreen} setStatusloading={setStatusloading} list={list} setList={setList}/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
});
