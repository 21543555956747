import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,ActivityIndicator,Image} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import {TextInput,Modal,Button,ImagePicker,Form} from "../assets/component";
import COLORS from "../assets/component/colors";
import {AddAccount,UpdateAccount} from "../assets/js/firebase";



export default function AddNewAccount(props) {

    const {add,close,update,account} = props

    const [values,setValues] = useState(null)

    const [isLoading, setIsloading] = useState(false);
    const [error, setError] = useState("");

    const [file, setFile] = useState("");

    
    useEffect(() => {

        if(account){
            setValues(account)
            setValue(setFile,account.logo)
        }


    }, [account]);

    
    function setValue(set,val){
        if(val){set(val)}
    }


    async function Validate(){

        setError('')
        const valid = Validation(['display_name','name','pic','ssm','tel','address','bank','acc']);
        const validNum = ValidationNum(['acc']);
        if(valid && validNum){
        setIsloading(true)

            if(!update){
                const newid = await AddAccount(values)
                if(newid){
                    setIsloading(false)
                    add(newid)
                }
            }else{
                console.log("UPdate")
                const ID =  values.id
                delete values.id
                const status = await UpdateAccount(ID,values)
                setIsloading(false)
                add(status)
            }
      }

  }




function Validation(obj){
    var validated = true
    obj.map((name)=>{
        if(!values[name] || values[name].error){
            setValues((prevState) => ({ ...prevState, [name]: {error:"Required"} }));
            console.log(name)
            validated = false
        }
    })
    return validated
}

function ValidationNum(obj){
    var validated = true
    obj.map((name)=>{
        if(values[name] <= 0 || isNaN(values[name]) || values[name].error){
            setValues((prevState) => ({ ...prevState, [name]: {error:"Only Integer Value"} }));
            validated = false
        }
    })
    return validated
}

  

  const Error = (props) => {
    if(!props.error) return null
    return (
        <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
    )
  }


  const Footer = () => {
    return (
        <View style={{flexDirection:'row-reverse'}}>
            {!isLoading?<Button title={update?'UPDATE':'CREATE'} icon={'md-add-circle'} onPress={() => Validate()}/>:<ActivityIndicator size={'small'}/>}
        </View>
    )
}

    return (
        <Modal title={update?'Update Account':'Create New'} icon={'person-add'} visible={true} setVisible={close} footer={Footer()}>
                    <View>
                            <Form 
                                data={
                                        [
                                            [{name:'logo',display:'Logo',image:true,size:65},{name:'display_name',display:'Display Name'}],
                                            [{name:'name',display:'Company Name',flex:1},{name:'ssm',display:'SSM',remark:'xxxxxx-a'}],
                                            [{name:'pic',display:'PIC',remark:'person in charge'},{name:'tel',display:'Tel'},{name:'code',display:'Invoice Code',remark:'XX-XXXXX'}],
                                            [{name:'address',display:'Address',flex:1,line:4},{name:'warehouse',display:'Warehouse Address',flex:1,line:4}],
                                            [{name:'website',display:'Website'}],
                                            [{name:'bank',display:'Bank',flex:1},{name:'acc',display:'Account'}],
                                        ]
                                    }
                                value={values}
                                set={setValues}
                            />
                            <Error error={error}/>
                    </View>
            </Modal>
    )

    

}

const styles = StyleSheet.create({
    row:{
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
    },
     
  });
  