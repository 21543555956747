import React, { useState, useEffect } from 'react';
import {Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import COLORS from "../../../../assets/component/colors";


export default function CategoryTab(props) {

    const {set,products} = props


    const [more, setMore] = useState(false);
    const [List, setList] = useState([]);
    const [types, setTypes] = useState([]);
    const [activeCat, setActiveCat] = useState('All');

    
    useEffect(() => {
        setList(products)
        let types = [... new Set(products.map(x=>x.type))];
        types.unshift('All')
        setTypes(types);
    }, []);

    useEffect(() => {
      if(List.length){
        if(activeCat == 'All'){
          set(List)
        }else{
          var filteredArray = List.filter((item) => item['type'] === activeCat);
          set(filteredArray)
        }
      }
    }, [List,activeCat]);



    const MoreBtn = () => {
      return (
        <View>
            <TouchableOpacity onPress={()=>setMore(!more)}>
                <Ionicons name={"more-vert"} size={22} color={COLORS.Secondary}/>
            </TouchableOpacity>
        </View>
      )
    }

    function setValue(key){
      setActiveCat(key)
    }

    if(!products.length) return null


    return (
      <View style={{flexDirection:'row',marginVertical:5,}}>
        <View style={{flexDirection:'row',flexWrap:'wrap',flex:1}}>
              {types.map(key => {
                const Colors = key == activeCat || !activeCat && key == 'All'?COLORS.Primary:'#777'
                  return (
                      <TouchableOpacity key={key} style={[styles.list,{borderColor:Colors}]} onPress={()=>{setValue(key)}}>
                          <Text style={[styles.cat,{color:Colors}]}> {key} </Text>
                      </TouchableOpacity>
                  ); 
                  })}
        </View>
        <MoreBtn/>
        
      </View>
    )



}

const styles = StyleSheet.create({
  list:{
    flexDirection:'row',
    borderWidth:0.5,
    borderRadius:4,
    padding:5,
    marginRight:5,
    marginBottom:5,
  },
  cat:{
     textTransform: 'capitalize'
  }
});
