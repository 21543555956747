import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,ActivityIndicator,Linking} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import {GetDocument,CURRENT_ACC} from "../../../../../assets/js/firebase";
import COLORS from "../../../../../assets/component/colors";
import {Button,Text} from "../../../../../assets/component";


export default function DoLINK(props) {

    const {data,type,modal} = props

    const [products, setProducts] = useState([]);
    const [loading, setIsloading] = useState(false);
    const [account, setAccount] = useState(null);

    const orderType = type?type:data.type


    useEffect(() => {
      GetAccount()
    }, []);



    async function GetAccount(){
      const Acc = await CURRENT_ACC()
      setAccount(Acc.id)
    }


    const loadOrder = (data) => {
  
        async function Getdata(){
          setIsloading(true)
          const DataList  = await GetDocument(data.id,orderType)
          const FilterNonservice = DataList.products.filter(({type}) => !type.includes('service'))
          setProducts(FilterNonservice)
          setIsloading(false)
        }
        
        if(loading){
            return <ActivityIndicator size={'small'}/>
        }
  
        return <Button onPress={()=>Getdata()} icon={'logo-dropbox'} title={'Get Product'} size={'small'}/>

  }


  const DoBtn = () => {

    if(!data) return null

    const driverMobile  = modal == 'delivery'?data.mobile : data.driver.mobile
    const Link = 'http://deliverydkz.web.app/delivery/'+account+'/'+data.id+'/'+orderType+'/'+driverMobile.slice(2)
    return (
      <Button onPress={()=>window.open(Link, '_blank')} icon={'link'} title={'DO'} size={'small'}/>
    )
  }


  const WhatsAppBtn = () => {

    if(!data) return null
    return (
      <Button onPress={()=>WhatsappLink(data)} icon={'logo-whatsapp'} title={'whatsapp'} size={'small'}/>
    )
  }



        
  


        const ProductListing = () => {

            if(!products.length) return null
            
              return(
                <View>
                  <Text style={{fontWeight:'500',color:COLORS.Primary}}>Products</Text>
                  <View style={{flexDirection:'row'}}><Text style={{flex:1,fontWeight:'500'}}>#</Text><Text style={{flex:5,fontWeight:'500'}}>Name</Text><Text style={{flex:1,fontWeight:'500'}}>Qty</Text></View>
                  {products.map((val,i)=> {
                      return (
                        <View key={i} style={{flexDirection:'row'}}>
                          <Text style={[styles.product,{flex:1}]}>{(i+1)}</Text>
                          <Text style={[styles.product,{flex:5}]}>{val.name}</Text>
                          <Text style={[styles.product,{flex:1}]}>{val.qty}</Text>
                        </View>
                      )
                })}
                </View>
              )
            
          }


          function WhatsappLink(data){

            var Whatsapp = '*Delivery Notification*'+'%0a%0a'
        

              const Link = 'http://deliverydkz.web.app/delivery/'+account+'/'+data.id+'/'+orderType+'/'+driverMobile.slice(2)
              Whatsapp += (1)+'. 📍'+data.delivery
              Whatsapp += "%0a . *MOBILE* : "+data.customer_mobile.substring(2)
              Whatsapp += "%0a . *NAME* : "+data.customer_name
              Whatsapp += '%0a . *DO*  : `' +Link+'` %0a%0a'

              Whatsapp += '%0a'+'> Please sign all DO with photo of proof on the link for each order'
        
              Linking.openURL('whatsapp://send?text='+Whatsapp+'&phone='+driverMobile)
          }

          
        if(data.proof || !account) return null
        if(modal == 'order' && !data.driver) return null

  
        return (
            <View>
              <View style={{flexDirection:'row',flexWrap:'wrap'}}>
                    <DoBtn/>
                    <Button onPress={()=>WhatsappLink(data)} icon={'logo-whatsapp'} title={'whatsapp'} size={'small'}/>
                    {loadOrder(data)}
              </View>
              <ProductListing/>
            </View>
        )


}

const styles = StyleSheet.create({
  text:{
    textTransform: 'capitalize',
    fontSize:16,
  },
  btn:{
    flexDirection:'row',
    alignContent:'center',
    padding:2,
    paddingHorizontal:5,
    marginBottom:5,
    marginRight:5,
    alignItems:'center',
    justifyContent:'center',
    borderWidth:1,
    borderColor:'#eee',
    borderRadius:3
},
deliverytxt:{
    color:COLORS.Link,
    fontSize:14
  },
  link:{
    color:COLORS.Link,
    fontSize:16,
    marginLeft:2
  },
});
