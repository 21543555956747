import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text } from 'react-native';
import {TextInput} from "../../../../../assets/component";


export default function pendingbox(props) {

    const {data,change} = props


    const Delivered = (proof) => {
        if(!proof) return null
        return (
            <View style={{width:40,padding:5}}>
                <Text style={styles.delivered}>Delivered</Text>
            </View>
        )
    }
    


    data['paid'] = data.paid?data.paid:0
    const BG = data.proof?'#e2e8f9':null
    const Amount = parseFloat(data.amount)
    const Paid = parseFloat(data.paid)
    const Balance = (Amount - Paid).toFixed(2)

    const ValueInput = () => {

        if(change){
            return(
                        <View style={{width:120}}>
                            <TextInput onChangeText={(val)=>change(val)} value={data.paying}/>
                            <Text style={[{fontStyle:'italic',fontWeight:600,fontSize:12}]}>BALANCE : {Balance}</Text>
                        </View>
            )
        }
        
        return (
        <View style={{flexDirection:'row'}}>
            {Delivered(data.proof)}
            <View style={styles.desc}>
                    <Text style={styles.item2}>TOTAL :</Text>
                    <Text style={[styles.item2,{color:'red'}]}>PAID :</Text>
                    <Text style={[styles.item2,{fontStyle:'italic',fontWeight:600}]}>BALANCE :</Text>
            </View>
            <View style={styles.val}>
                    <Text style={styles.item2}>{Amount.toFixed(2)}</Text>
                    <Text style={[styles.item2,{color:'red'}]}>-{Paid.toFixed(2)}</Text>
                    <Text style={[styles.item2,{fontStyle:'italic',fontWeight:600}]}>{Balance}</Text>
            </View>
        </View>
        )
    }

  
    return (
      <View style={[styles.bx,{backgroundColor:BG}]}>
            <View style={{flex:1,paddingRight:5}}>
                    <Text style={{color:'#43a568'}}>#{data.id}</Text>
                    <Text style={styles.item}>{data.name}</Text>
                    <Text style={styles.item}>{data.company}</Text>
            </View>
            {ValueInput()}
      </View>
    )


}

const styles = StyleSheet.create({
    bx:{
        borderBottomWidth:0.5,
        padding:5,
        borderColor:'#aaa',
        flexDirection:'row'
    },
    item:{
        fontSize:14
    },
    item2:{
        fontSize:13
    },
    delivered:{
        color:'red',
        fontSize:12
    },
    desc:{
        width:70,
        alignContent:'flex-end',
        alignItems:'flex-end',
        marginRight:5
    },
    val:{
        width:70,
        alignContent:'flex-end',
        alignItems:'flex-start'
    }
});
