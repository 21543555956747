import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text} from 'react-native';



export default function DetailsCol(props) {

    const {data} = props

    const Details = (data,val) => {
        return(
            data.map((key,i) => {
              return <Text key={i}>{val&& ": " }{key}</Text> 
            })
        )
      }

    if(!data) return null
    

    return(

        <View>
            <Text style={{fontWeight:'500',fontSize:18}}>ORDER DETAILS</Text>
            <View style={{flex:1,flexDirection:'row',padding:5}}>
                <View style={{width:80}}>{Details(['ID','Date','Credit Note','Name','Company','Mobile','Amount','Paid','Remark','Address'])}</View>
                <View>{Details([data.id,data.date,data.credit_note,data.name,data.company,data.mobile,'RM'+data.amount,'RM'+data.paid,data.remark,data.address],true)}</View>
            </View>
        </View>

    )



}

const styles = StyleSheet.create({
  text:{
    textTransform: 'capitalize',
    fontSize:16,
  },
  tabActive:{
    borderWidth:1,
    borderTopLeftRadius:10,
    borderTopRightRadius:10,
    borderBottomWidth:0,
    borderColor:'#ccc',
    borderBottomColor:'#fff',
    backgroundColor:'#fff'
  },
  tabInActive:{
    borderBottomWidth:1,
    borderColor:'#ccc'
  },
});
