import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList } from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import COLORS from "../../../../../assets/component/colors";
import {GetOrders } from "../../../../../assets/js/firebase";
import Box from "./box";

export default function listpending(props) {

    const {changeHandler,OrderChoose} = props

    const [isLoading, setIsloading] = useState(true);
    const [orderlist, setOrderList] = useState([]);


    useEffect(() => {

            getPendingOrders()

    }, []);



    async function getPendingOrders(){

        const DataList  = await GetOrders('orders','pending',null)

        const sortedArray = [
            ...DataList.list.filter(({proof}) => proof),
            ...DataList.list.filter(({proof}) => !proof)
        ];

          setOrderList(sortedArray);
          setIsloading(false)
    };





const RenderItem = ({item,index}) => {
    item['paid'] = item.paid?item.paid:0
    const BG = item.proof?'#e2e8f9':null
    const Balance = (parseFloat(item.paid) - parseFloat(item.amount)).toFixed(2)
    return(
      <TouchableOpacity onPress={()=>OrderChoose(item)}>
        <Box data={item}/>
      </TouchableOpacity>
    )
}


    return (
        <View style={{flex:1}}>
            <TouchableOpacity style={styles.others}  onPress={()=>OrderChoose('others')}>
                    <Ionicons name='add' size={18} color={COLORS.Error} />
                <Text>ADD OTHER</Text>
            </TouchableOpacity>

            <FlatList
                showsHorizontalScrollIndicator={false}
                data={orderlist}
                renderItem={RenderItem}
                onEndReachedThreshold={0}
            />
        </View>
    )




}

const styles = StyleSheet.create({
    others:{
        borderWidth:1,
        padding:5,
        borderColor:'#aaa',
        marginBottom:5,
        flexDirection:'row',
        borderRadius:5,
        backgroundColor:'#eee'
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
});
