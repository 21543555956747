import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert} from 'react-native';
import {Ionicons,MaterialCommunityIcons} from '@expo/vector-icons';
import moment from 'moment'
import UpdateDriverModal from '../update';
import {CheckBox} from "../../../../assets/component";



export default function Table(props) {

  const {list,update,setPicked,picked,tab} = props

  const [driver, setDriver] = useState(null);


  useEffect(() => {


  }, []);






    const CircleCount = (num) => {
      const Bgcolor = num > 0 ? '#ff6062' : '#9fc0ff'
      return (
        <View style={[styles.countbx,{backgroundColor:Bgcolor}]}>
          <Text style={styles.counttxt}>{num}</Text>
        </View>
      )
    }

    const Remark = (data) => {
      if(!data) return null
      return (

        <View style={{flexDirection:'row',alignContent:'center',alignItems:'center'}}>
            <Ionicons name='information-circle-outline' size={14} color={'#ff7c2a'} style={{marginRight:4}}/>
            <Text style={{color:'#ff7c2a',fontSize:12}}>{data}</Text>
         </View>
      )
    }

    const DeliveryAdd = (data) => {
      if(data.length > 80){
        data = '...'+data.substr(data.length - 80)
      }
      return (
        <Text style={[styles.item,{fontWeight:'200',fontSize:12}]}>{data}</Text>
      )
    }

    const Thickbox = (data) => {
      if(tab !== 'delivered') return null
      var containsId = picked.find(x => x.id === data.id);

      return (
          <View style={{alignItems:'center'}}>
            <CheckBox value={containsId} onValueChange={()=>SelectId(data,containsId)} />
          </View>
      )
    }

    function SelectId(data,found){
      if(found){
        const filteredData = picked.filter(item => item.id !== data.id);
        setPicked(filteredData);
      }else{
        setPicked([...picked, data])
      }
    }



    const RenderItem = ({item,index}) => {
      const createdAt = moment(item.createdAt.toDate());
      const Days =  moment().diff(createdAt, 'days')
      const GetLastStatus = item.delivery_status.at(-1).status
      const StatusCount = item.delivery_status.length

      var GetLastIndex = item.delivery.lastIndexOf(",");
      const  State = item.delivery.substring(GetLastIndex + 1);

      const Picked = GetLastStatus == 'pickup' ? 0.2 : 1

      return(
        <TouchableOpacity onPress={() => setDriver(item)}  style={{flexDirection:'row',padding:5,opacity:Picked}}>
          {Thickbox(item)}
                  <View style={{flex:3}}>
                        <View style={{flexWrap:'wrap'}}>
                            <Text style={styles.title}>{item.name}</Text>
                            {item.company_transporter && <Text style={{color:'red'}}>in-house</Text>}
                            {DeliveryAdd(item.delivery)}
                            {Remark(item.remark)}
                        </View>
                  </View>
                  <View style={{flex:1,alignItems:'center'}}>
                    {CircleCount(Days)}
                    <Text style={styles.item}>Days</Text>
                  </View>
          </TouchableOpacity>
      )
  }







    return(
      <View style={styles.container}>
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
          />
          <UpdateDriverModal data={driver} clear={setDriver} update={(val)=>update(val)} />
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  id:{
    marginRight:5,
    color:'#006ad4',
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    position:'absolute',
    backgroundColor:'#fff',
    zIndex:1,
    left:-90,
    borderRadius:5,
    padding:10,
    paddingHorizontal:20,
    shadowOffset: { width: 2, height: 4 },
        shadowRadius: 10,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  counttxt:{
    fontSize:10,
    color:'#fff'
  },
  countbx:{
    width:15,
    height:15,
    borderRadius:10,
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center',
  },
  date:{
    fontSize:12,
    color:'#03a9f4'
  }
});
