import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,ActivityIndicator, Platform} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import COLORS from "../../../../assets/component/colors";
import {ListDownoverlay,Button,Modal,TextInput,Copy} from "../../../../assets/component";
import {AddShopeeOrder,GetAllDocs} from "../../../../assets/js/firebase";
import Products from '../../Order/add/productslist'
import InputDelivery from './inputdelivery'

export default function AddNewOrder(props) {

    const {add,data,platformList,platform} = props

    const [modalVisible, setModalVisible] = useState(false);
    const [modalProducts, setModalProducts] = useState(true);
    const [isLoading, setIsloading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [orderid, onOrderid] = useState("");
    const [delivery, onDelivery] = useState("");
    const [company, onCompany] = useState(null);
    const [error, setError] = useState("");
    const [selected, setSelected] = useState([]);
    const [productList, setProductList] = useState(null);


    useEffect(() => {
      if(data){
        onOrderid(data.orderid)
        onCompany(data.company)
      }
    }, [data]);

    useEffect(() => {
        onCompany(platform)
    }, [platform]);


  useEffect(() => {
    const getDatas = async () => {
      const ListProduct = await GetAllDocs('products','active')
      setProductList(ListProduct)
    }
    getDatas()
  }, []);



    const onChangePrice = (index,val) => {
      var val = val.replace(',', '')
        setSelected((prev) =>
          prev.map((userScore,idx) => {
            if (idx === index) {
              return {
                ...userScore,
                amount: val?val.replace(/^0+/, ''):'0'
              };
            } else {
              return userScore;
            }
          })
        );
      };

      const onChangeQty = (index,val,stk) => {
        const qty = val?parseInt(val):0
        setSelected((prev) =>
          prev.map((userScore,idx) => {
            if (idx === index) {
              return {
                ...userScore,
                qty: parseInt(qty)
              };
            } else {
              return userScore;
            }
          })
        );
      };




    async function Validate(){
        setError('')

        const Income = selected.reduce((n, {amount,qty}) => n + (parseFloat(amount)*parseInt(qty)), 0).toFixed(2)

        var valid = Validation(orderid,onOrderid);
        var valid2 = ValidationAmt(Income);
   
        if(valid && valid2 ){
                setIsloading(true)
                selected.forEach(e => {delete e.stock,delete e.createdAt});
                const ValidateData = {
                  'orderid':orderid,
                  'company':company,
                  'income':Income,
                  'products':selected,
                  'delivery':delivery
                }
                const Added = await AddShopeeOrder(ValidateData)
                setSuccess(Added.id)
                add(Added)
        }

    }

    function Validation(input,change){
        if(!input || input.error){
            change({error:"Invalid Value"});
            return false
        }
        return true
    }

    function ValidationAmt(input){

      if(input <= 0 || isNaN(input) || input.error){
        return false
      }
      return true
    }


    function clear(){
        setIsloading(false)
        setSuccess(null)
        onOrderid("")
        onDelivery("")
        setSelected([])
        setModalVisible(false)
        setModalProducts(true)
        onCompany(platform)
    }


    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }



    function RemoveProducts(index){
        const temp = [...selected];
        temp.splice(index, 1);
        setSelected(temp);
        if(!temp.length){
            setModalProducts(true)
        }
    }






    function SelectProduct(data) {
      setSelected(data)
      setModalProducts(false)
    }




    const ProductSelected = (item) => {

        function qtyChanger(i,val,stock){
          if(val <= stock){
            onChangeQty(i,val,stock)
          }
        }

        const aaa = () => item.map((i, index) => {

            const Stock = i.type == 'stock'?true:false

            return (
                <View style={[styles.orderSelect,{ backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }]} key={"Selected"+index}>
                    <View style={{flex:4}}>
                        <Text>{i.name}</Text>
                        {Stock &&
                          <View style={{flexDirection:'row'}}> 
                            <Text>stock : </Text>
                            <Text style={{color:COLORS.Link}}>{i.stock}</Text>
                          </View>
                        }
                    </View>
                    <View style={{width:50,marginRight:5}}>
                        {Stock && <TextInput onChangeText={(val)=>qtyChanger(index,val,i.stock)} value={i.qty}/>}
                    </View>
                    <View style={{width:100}}>
                        <TextInput onChangeText={(val)=>onChangePrice(index,val)} value={i.amount} keyboardType="decimal-pad"/>
                    </View>
                    <TouchableOpacity onPress={()=>RemoveProducts(index)} style={{width:20}}>
                        <Ionicons name='close' size={18} color={COLORS.Error} />
                    </TouchableOpacity>
                </View>
            )
        })

        return (
            <View style={{marginBottom:5}}>
                <View style={{alignItems:'center',flexDirection:'row'}}>
                    <TouchableOpacity style={styles.additem} onPress={()=> setModalProducts(true)}>
                      <Ionicons name='add-box' size={22} color={COLORS.Primary} />
                      <Text style={{color:COLORS.Primary,marginLeft:2}}>ADD ITEM</Text>
                    </TouchableOpacity>
                </View>
                {headerProducts()}
                {aaa()}
                <View style={{flexDirection:'row',alignContent:'flex-end',alignItems:'flex-end'}}>
                  <Text style={{fontWeight:'bold',fontSize:18}}>RM{selected.reduce((n, {amount,qty}) => n + (parseFloat(amount)*parseInt(qty)), 0).toFixed(2)}</Text> 
                </View> 
            </View>
        )
    }

    const Success = () => {
      return (
        <View style={{alignContent:'center',alignItems:'center'}}>
            <Copy link={success} title={success}>
              <View style={styles.center}>
                  <Text style={{fontWeight:'500',fontSize:22,marginRight:5}}>{success}</Text>
                  <Ionicons name='content-copy' size={14} color={'#aaa'} />
              </View>
            </Copy>
            <Button title={'OK'} onPress={() => clear()}/>
        </View>
      )
    }



    const OrderAddPopup = (data) => {
      if(success) return <Success/>
        return(
                <View style={{flex:1}}>
                        <View style={{zIndex:99}}>
                                <TextInput onChangeText={onOrderid} maxLength={60} title="OrderId" value={orderid} autoCapitalize="true" errclear={true}/>
                                <InputDelivery value={delivery} set={onDelivery} platform={company}/>
                                <ListDownoverlay name={'Company'} onPress={onCompany} list={platformList} active={company}/>
                        </View>
                        {ProductSelected(data)}
                        <Error error={error}/>
                        {!isLoading?<Button title={'ADD'} icon={'md-add-circle'} onPress={() => Validate()}/>:<ActivityIndicator size={'small'}/>}
                </View>
        )
    }
    

    const headerProducts = () => {
      return (
        <View style={{flexDirection:'row',padding:2}}>
          <View style={{flex:1}}><Text style={styles.th}>ID</Text></View>
          <View style={{width:50}}><Text style={styles.th}>QTY</Text></View>
          <View style={{width:100}}><Text style={styles.th}>PRICE</Text></View>
          <View style={{width:20}}></View>
        </View>
      )
    }



    const Popup = () => {
        if(!modalVisible) return null
        return (
                <Modal title={modalProducts?'Choose Product':'Add Order'} icon={'ios-receipt'} visible={modalVisible} setVisible={clear} headColor={'#428ff5'}>
  
                      {modalProducts?<Products setValue={(val)=>SelectProduct(val)} selected={selected} products={productList}/>:OrderAddPopup(selected)}

                       
                </Modal>
        )
    }




    return(
        <View style={styles.container}>
                <View style={{flex:1}}/>
                <Button title={'ADD ORDER'} icon={'md-add-circle'} style={{padding:data?0:2}} onPress={() => setModalVisible(true)} color={'#e3a600'}/>
                {Popup()}
        </View>
    )

}

const styles = StyleSheet.create({
    container:{
      flexDirection:'row',
      flexGrow:1,
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },

      orderSelect:{
        padding:5,
        flexDirection:'row',
        borderBottomWidth:1,
        borderStyle:'dashed',
        borderColor:'#cecece'
      },
      additem:{
        borderWidth:1,
        padding:5,
        marginVertical:5,
        flex:1,
        borderStyle:'dashed',
        marginRight:10,
        borderRadius:5,
        borderColor:COLORS.Primary,
        alignItems:'center',
        alignContent:'center',
        flexDirection:'row',
        justifyContent:'center'
      },
      deliverybtn:{
        padding:10,
        alignContent:'center',
        alignItems:'center'
      },
      deliverytxt:{
        color:COLORS.Primary,
        fontSize:14
      },
      th:{
        color:COLORS.Primary,
        fontSize:12
      },
      reorder:{
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      }
  });
  