import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,Linking} from 'react-native';
import COLORS from "../../../../assets/component/colors";
import {Modal,Button,Link} from "../../../../assets/component";
import {DeliveryPriority,CURRENT_ACC,GetDocsByids} from "../../../../assets/js/firebase";
import PackingList from "./packinglist"

export default function Footer(props) {

    const {picked,setPicked,screen,setStatusloading,list,setList} = props


    const [items, setiItem] = useState([]);
    const [packing, setPacking] = useState([]);
    const [account, setAccount] = useState(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {

      GetAccount()
      setiItem(picked)
      setPacking([])
    }, [picked]);

    async function GetAccount(){
      const Acc = await CURRENT_ACC()
      setAccount(Acc.id)
    }


    async function Priority(data,priority){
      setStatusloading(true)
      await DeliveryPriority(data,priority)
      UpdateStatus(data,priority)
    }
  
    function UpdateStatus(data,priority){
      const New = list['pending'].map(function(x){ 
        var result=data.filter(a1=> a1.id==x.id);
        if(result.length>0) { x.priority=priority;}
        return x })
  
        
  
        const dataArray = [ ...New]
        
        var sorted = dataArray.sort((a,b) => {
            if(b.priority === undefined) return -1;
            if (a.priority < b.priority) return 1;
            if (a.priority > b.priority) return -1;
            return 0;
        })
        setList({...list, ['pending']:sorted});
        setPicked([])
    }
  


    function WhatsappLink(data){

      var Whatsapp = '*Delivery Notification*'+'%0a'
  
      Whatsapp += 'Total : *'+data.length +'*%0a%0a'
  
      data.map((val,i)=>{
        const Link = 'http://deliverydkz.web.app/delivery/'+account+'/'+val.id+'/'+val.type+'/'+val.mobile.slice(2)
        Whatsapp += (i+1)+'. 📍'+encodeURIComponent(val.delivery)
        Whatsapp += "%0a . *MOBILE* : "+val.customer_mobile.substring(2)
        Whatsapp += "%0a . *NAME* : "+val.customer_name
        Whatsapp += '%0a . *DO*  : `' +Link+'` %0a%0a'
      })
        Whatsapp += '%0a'+'> Please sign all DO with photo of proof on the link for each order'
  
      Linking.openURL('whatsapp://send?text='+Whatsapp+'&phone='+data[0].mobile)
    }

  async function ExecuteBtn(val){
    const OnlineIds = items.filter(item => item.type === 'online_order').map((l) => l.id );
    const OrderIds = items.filter(item => item.type === 'orders').map((l) => l.id );
    const Result1 = await GetDocsByids('online_order',OnlineIds)
    const Result2 = await GetDocsByids('orders',OrderIds)
    const Merge = [...Result1,...Result2]
    const a3 = Merge.map(t1 => ({...t1, ...items.find(t2 => t2.id === t1.id)}))

    setiItem(a3)

    
    const totals = [];
    a3.forEach(x => {
      x.products.forEach(x2 => {
        const obj = totals.find(o => o.id === x2.id);
        if (obj) {
          obj.qty = obj.qty + x2.qty;
        } else {
          totals.push(x2);
        }
      });
    });

    setPacking(totals);

    if(val == 'packinglist'){
      setVisible(true)
    }
    if(val == 'whatsapp'){
      WhatsappLink(a3)
    }

  }
  

 
  if(!picked.length) return null
    
  const prior = picked[0].priority == 1 ? 0 : 1

    return (
      <View style={{flexDirection:'row',padding:10,backgroundColor:'#ffeacd',position:'absolute',width:'100%',bottom:0}}>
          <View style={{flex:1}}>
              <View><Text>TOTAL : {picked.length}</Text></View>
              <Link onPress={()=>setPicked([])}>Clear All</Link>
          </View>
          <PackingList picked={picked} packing={packing} visible={visible} setVisible={setVisible}/>
          <Button title={!screen && 'Packing List'} onPress={()=> ExecuteBtn('packinglist')} color={'#255366'} style={{marginRight:10}} icon={'document'}/>
          <Button title={!screen && 'Whatsapp'} onPress={()=> ExecuteBtn('whatsapp')} color={'#25D366'} style={{marginRight:10}} icon={'logo-whatsapp'}/>
          <Button title={prior !== 1 ? 'Normal' : 'Priority'} onPress={()=>Priority(picked,prior)} color={'#fc3063'} style={{marginRight:10}} icon={'return-up-back'}/>
      </View>
    )

    

}

const styles = StyleSheet.create({
    container:{
      padding:10,
      flexDirection:'row',
      flexGrow:1,
      borderBottomWidth:1,
      borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    btn:{
        flexDirection:'row',
        alignContent:'center',
        padding:2,
        paddingHorizontal:5,
        marginBottom:5,
        marginRight:5,
        alignItems:'center',
        justifyContent:'center',
        borderWidth:1,
        borderColor:'#eee',
        borderRadius:3
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      orderSelect:{
        borderRadius:5,
        padding:5,
        borderColor:'#aaa',
        flexDirection:'row',
        backgroundColor:'#eee'
      },
      input2:{
        width:50,
      },
      deliverybtn:{
        padding:10,
        alignContent:'center',
        alignItems:'center'
      },
      deliverytxt:{
        color:COLORS.Link,
        fontSize:14
      },
      link:{
        color:COLORS.Link,
        fontSize:16,
        marginLeft:2
      },
      bullet:{
        width:8,
        height:8,
        borderRadius:8,
        borderColor:'#428876',
        borderWidth:1,
        backgroundColor:'#428876'
      },
      bullet2:{
        width:8,
        height:8,
        borderRadius:8,
        borderColor:'#428876',
        borderWidth:1
      },
      product:{
        fontSize:14,
        color:'#444'
      }
  });
  