import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator } from 'react-native';
import moment from 'moment';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import COLORS from "../../../../assets/component/colors";
import {ListDown,TextInput,Button,Form} from "../../../../assets/component";
import {AddCredit,updateImageUrl,getCreditTypes,GetDocument} from "../../../../assets/js/firebase";
import {UploadData} from "../../../../assets/js/upload";
import CreditNote from "./debit/creditnote";
import Searchinput from '../search';


export default function CreditsModal(props) {

    const {success} = props

    const [isLoading, setIsloading] = useState(false);
    const [values,setValues] = useState({'name':'','subcategory':"",'category':"",'amount':0,'dated':'','remark':''})
    const [credittype, setCreditType] = useState({base:null,active:null});
    const [creditnote, setCreditNote] = useState(null);

    useEffect(() => {

        getTypes()

    }, []);

    useEffect(() => {

        if(creditnote){
            const CatName = 'Credit Note'
            const Found = credittype.base.find(x => x.name === CatName);
            const Amt = creditnote.paid ? creditnote.amount - (creditnote.paid.reduce((n, {amount}) => n + amount, 0)) : creditnote.amount;
            const NewVal = {
                'name':Found,
                'category':{category:CatName,name:CatName,sub_category:'refund'},
                'amount':Amt,
                'dated':values.dated,
                'remark':'',
            }
            if(!Found){
                NewVal['name'] = CatName
                NewVal['category'] = {add:CatName}
                NewVal['subcategory'] = 'refund'
            }
            setValues(NewVal)
        }

    }, [creditnote]);

    function changeHandler (val,type){
        setValues( prevValues => {
          return { ...prevValues,[type]: val}
        })
      }



      async function getTypes(){

        const DataList  = await getCreditTypes()

        setCreditType(DataList);
    };


    function ADDNEWCAT(val){
        changeHandler(val == 'Add New'?{add:''}:val,'category')
        changeHandler('','subcategory')
    }





async function Validate(){
        var valid = true;
        var Cat = null;

        if(!values.name || values.name.error){
            changeHandler({error:"Invalid Name"},'name')
            valid=false
        }

        if(values.name && !values.name.name){
            if(typeof values.category === 'object' && !values.category.add || values.category.error){
                changeHandler({error:"Invalid Category"},'category')
                alert("Set New Category")
                valid = false
            }else{
                Cat = values.category.add?values.category.add:values.category
            }
        }

        if(!values.dated || values.dated.error){
            changeHandler({error:"Amount must not be 0"},'dated')
            valid=false
        }

        if(values.amount <= 0 || isNaN(values.amount) || values.amount.error){
            changeHandler({error:"Amount must not be 0"},'amount')
            valid=false
        }
   
        if(valid){
            setIsloading(true)
            const Newval = {'category':values.name,'amount':values.amount,status:'active','date':values.dated,'remark':values.remark}
            if(creditnote){
                Newval['creditnote'] = creditnote.id
            }
            const gEtDoc = await AddCredit(Newval,Cat,values.subcategory);
            if(Cat){
                const dataArray = [...credittype['base']]
                dataArray.unshift({category: Cat,sub_category:values.subcategory, name: values.name,id: gEtDoc.catid})
                if(values.category.add){
                    const catArray = [...credittype['category']]
                    catArray.unshift(Cat)
                    setCreditType({...credittype, ['base']:dataArray, ['category']:catArray});
                }else{
                    setCreditType({...credittype, ['base']:dataArray});
                }

            }
            if(values.file) {
                const DownloadUrl = await UploadData(gEtDoc.id,values.file,'credits') 
                if(DownloadUrl){
                    setIsloading(false)
                    const YearDir = moment(gEtDoc.date.toDate()).format('YYYY');
                    updateImageUrl('credits',YearDir,gEtDoc.id,DownloadUrl)
                    gEtDoc['image'] = DownloadUrl
                    success(gEtDoc)
                }
            }else{
                setIsloading(false)
                success(gEtDoc)
            }
        }

    }


    

    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }


    const CloseBtn = (data) => {
        if(!data) return null
        return (
            <View style={{flexDirection:'row-reverse'}}>
                <TouchableOpacity onPress={()=> changeHandler(data,'category')}>
                    <Text>Close</Text>
                </TouchableOpacity>
            </View>
        )
    }

    const CreditNoteBtn = () => {
        const Toggle = values.payment == 'credit note' ? '' : 'credit note'
        return (
            <View style={{flexDirection:'row-reverse'}}>
                <TouchableOpacity onPress={()=> changeHandler(Toggle,'payment')} style={{borderWidth:1,borderColor:'#006cff',padding:10}}>
                    <Text style={{color:'#006cff'}}>Pay Credit Note</Text>
                </TouchableOpacity>
            </View>
        )
    }

    const SubBtn = (data) => {
        return (
                <TouchableOpacity onPress={()=> changeHandler(data,'category')} style={{marginBottom:10}}>
                    <Text style={{color:COLORS.Link,fontSize:12}}>Add Sub-category</Text>
                </TouchableOpacity>
        )
    }

    const SubcatInput = () => {
        return (
            <Form 
                    data={[[{name:'subcategory',display:'Sub Category',flex:1}]]}
                    value={values}
                    set={setValues}
            />

        )
    }

    const creditCategory = (data) => {
        var catetype = credittype.category
        var newCat = false
        if(!data || data.id) return null


        if(!values.category && catetype.length){
            changeHandler(catetype[0],'category')
        }

        if(!catetype.length || typeof values.category === 'object' || newCat){
            return (
                <View style={{backgroundColor:'#eee',padding:10,marginBottom:10,borderRadius:10}}>
                                {CloseBtn(catetype[0])}
                                <TextInput
                                    onChangeText={(val)=>changeHandler(val?{add:val}:catetype[0],'category')}
                                    maxLength={60}
                                    title="New Category"
                                    value={values.category.add?values.category.add:''}
                                    autoCapitalize="none"
                                    errclear={true}
                                    required={true}
                                /> 
                                {SubcatInput()} 

                </View>
            )
        }



        return (
            <View>
                        <Text style={{color:COLORS.Text,paddingHorizontal:5}}>Group</Text>
                        <ListDown list={['Add New',...catetype]} onPress={ADDNEWCAT} active={values.category?values.category:catetype[0]}/>
                        {SubBtn({add:values.category})}
            </View>

        )
  
    }

    function SetCN(data){
        if(data && data.status == 'pending'){
            setCreditNote(data)
        }else{
            setCreditNote(null)
        }
    }

    const CreditForm = () => {
       if(values.payment && !creditnote) return null
       return (

        <View>
                <Searchinput set={(val)=>changeHandler(val,'name')} value={values.name} data={credittype}/>
                {creditCategory(values.name)}
                <Form 
                        data={
                                    [
                                        [{name:'amount',display:'Amount',flex:1,editable:EditableAmt},{name:'dated',display:'Date',date:true}],
                                        [{name:'remark',display:'Remark',flex:1}],
                                        [{name:'file',display:'File',file:true,size:25}],
                                    ]
                            }
                        value={values}
                        set={setValues}
                />
        </View>
       )
    }




    const EditableAmt = creditnote && creditnote.id ? false :true


    return (
        <View style={styles.container}>
                <CreditNoteBtn/>
                {values.payment && <CreditNote values={values} set={setValues} credit={creditnote} setcredit={SetCN} amount={values.amount}/>}
                {CreditForm()}
                <Button title={'ADD'} isloading={isLoading} icon={'md-add-circle'} onPress={() => Validate()}/>
        </View>
    )



}

const styles = StyleSheet.create({
    container:{
        padding:5,
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },

    orderSelect:{
        flexDirection:'row',
        borderWidth:1,
        borderRadius:4,
        padding:5,
        borderColor:'#ddd',
        shadowOffset: { width: 2, height: 4 },
        shadowRadius: 10,
        shadowOpacity: 0.2,
        elevation: 15,
    },
    item:{
        fontSize:14
    }
});
