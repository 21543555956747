import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text, ActivityIndicator} from 'react-native';
import {YearSelector} from '../../../assets/component/momentselect';


export default function AccountHeader(props) {

    const {data,year,setyear} = props


    const CurrentCredit = data && data.total_credits?data.total_credits.toFixed(2):0
    const CurrentDebit = data && data.total_debits?data.total_debits.toFixed(2):0




    const Balance = () => {
      return (
        <View>
          <Text style={{fontSize:12,color:'#aaa'}}>Account Balance</Text>
          <Text style={{fontSize:18,color:'#00c96b',fontWeight:'700'}}>RM{numberWithCommas((CurrentDebit -CurrentCredit).toFixed(2))}</Text>
        </View>
      )
    }

    const Summary = () => {
      return (
        <View style={{flexDirection:'row',marginBottom:5}}>
          <View style={{marginRight:5}}>
              <Text>Credit</Text>
              <Text>Debit</Text>
          </View>
          <View>
              <Text style={{color:'#ff6d4d'}}>: RM{numberWithCommas(CurrentCredit)}</Text>
              <Text style={{color:'#00b5c9'}}>: RM{numberWithCommas(CurrentDebit)}</Text>
          </View>
        </View>
      )
    }

    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }



    return (
      <View style={styles.container}>
        <View style={styles.detailsbx}>
          <View style={{flex:1}}>
              <Summary/>
              <Balance/>
          </View>
          <YearSelector setYear={setyear} year={year}/>
        </View>
      </View>
 
    )
  }




const styles = StyleSheet.create({
  container:{
    zIndex:32
  },
  detailsbx:{
    borderBottomWidth:1,
    borderColor:'#eee',
    padding:5,
    backgroundColor:'#fff5d4',
    flexDirection:'row',
    zIndex:32
  },
  btnmore:{
    flexDirection:'row',
    padding:5,
    justifyContent:'center'
  }
});
