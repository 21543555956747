import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,FlatList} from 'react-native';
import {Modal,Text} from "../../../../assets/component";
import { ScrollView } from 'react-native-gesture-handler';

export default function PackingListScreen(props) {

    const {picked,setVisible,visible,packing} = props



    const RenderItem = ({item,index}) => {
      const BgColor =  index % 2 == 0  ? "#FFFFFF" : "#f2f2f2"


      return(
          <View style={{flexDirection:'row',padding:5,paddingVertical:15, backgroundColor:BgColor,flexWrap:'wrap',borderBottomWidth:0.5}}>
            <View style={{flex:1}}>
                <Text>{item.name}</Text>
            </View>
            <View style={{width:10,padding:5}}>
                <Text>{item.qty}</Text>
            </View>
          </View>
      )
  }

  const Footer = () => {
    return (
      <View style={styles.footer}>
        <View style={styles.row}>
          <Text style={{colors:'#ddd'}}>Orders : </Text>
          <Text style={styles.link}>{picked.length}</Text>
        </View> 
      </View>
    )
  }



    return (
                <Modal title={'Packing List'} icon={'document'}  visible={visible} setVisible={setVisible}>
                    <ScrollView>
                    <FlatList
                          contentContainerStyle={{ paddingBottom: 120 }}
                          showsHorizontalScrollIndicator={false}
                          data={packing}
                          renderItem={RenderItem}
                          onEndReachedThreshold={0}
                    />
                    </ScrollView>
                    <Footer/>
                </Modal>
    )

    

}

const styles = StyleSheet.create({
    footer:{
      flex:1,
      borderTopWidth:0.5,
      borderStyle:'dashed',
      padding:4,
      marginVertical:10,
    },
    link:{
      color:'blue'
    },
    row:{
      flexDirection:'row',
      alignContent:'center',
      width:100
    }
  });
  