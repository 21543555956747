import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Image} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import moment from 'moment';
import {MaterialIcons} from '@expo/vector-icons';
import Update from './update';

export default function Table(props) {

  const {tab,setList,list,loading} = props

  const [update, setUpdate] = useState(null);
  const [sortasc, setSortAsc] = useState(true);



  function Sortby(name,tab){
    var name = name.toLowerCase();
    const dataArray = [ ...list[tab]]
    var sorted = dataArray.sort((a,b) => {
      if(!sortasc){setSortAsc(true) ; return b[name].toString().localeCompare(a[name].toString())}
      setSortAsc(false) ;
      return ( a[name].toString().localeCompare(b[name].toString()) ) 
    })
    setList({...list, [tab]:sorted});
  }
  


const Th = (title,size,sort,center) => {
  return(
      <View style={{flex:size,alignItems:center && 'center'}}>
        <TouchableOpacity style={[styles.flexcenter]} onPress={()=>sort?Sortby(title,tab):null}>
          <Text style={styles.item}>{title}</Text>
          <View>
              {sort?<MaterialIcons name={sortasc?"arrow-drop-down":"arrow-drop-up"} size={20} color="#ccc" />:null}
          </View>
        </TouchableOpacity>
      </View>

  )
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          {Th('Name',2)}
          {Th('Job',1,false,true)}
          {Th('Created',1,true)}
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }

    const InHouse = (data) => {
      if(!data) return null
      return(
        <View style={{flexDirection:'row'}}>
          <View style={{padding:2,borderWidth:1,borderRadius:2,borderColor:'red'}}>
              <Text style={{fontSize:12,color:'red'}}>own transporter</Text>
          </View>
        </View>
      )
    }


    const RenderItem = ({item,index}) => {

      return(

        <TouchableOpacity onPress={() => setUpdate(item)}  style={{padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }}>
          <View style={{flexDirection:'row'}}>
              <View style={{flex:2}}>
                <Text  style={[styles.title]}>{item.name}</Text>
                <Text style={[styles.item]}>{item.mobile}</Text>
                {InHouse(item['in-house'])}
              </View>
              <View style={{flex:1,alignItems:'center'}}><Text style={[styles.item]}>{item.completed}</Text></View>
              <View style={{flex:1}}><Text style={[styles.item]}>{ReformatTime(item.createdAt)}</Text></View>
          </View>
        </TouchableOpacity>
      )
  }

  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD/MM/YY');
    return format
  }
  
  function UpdateData(data){
    const dataArray = [ ...list[tab]]
    let index = dataArray.findIndex(d=> d.id === data.id)
    if(data.status == 'active'){
      if(index >= 0){
        dataArray[index] = data  
      }else{
        dataArray.unshift(data)
      }
    }else{
      dataArray.splice(index, 1);
    }
    
    setList({...list, [tab]:dataArray})
    
  }

  const AddMore = () =>{
    return (
      <TouchableOpacity style={styles.addmore}  onPress={() => setUpdate({'add':true})}>
        <MaterialIcons name={"add-circle"} size={20} color="#fff" />
        <Text style={{color:'#fff'}}> ADD</Text>
      </TouchableOpacity>
    )
  }


    

    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <AddMore/>
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list[tab]}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
          />
          <Update data={update} clear={setUpdate} update={(val)=>UpdateData(val)} tab={tab}/>
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    alignItems:'center',
    flexDirection:'row',
    backgroundColor:'#fff',
    borderRadius:5,
    padding:10,
    paddingHorizontal:10,
    marginHorizontal:5,
    shadowOffset: { width: 2, height: 2 },
        shadowRadius: 5,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  addmore:{
    borderRadius:5,
    marginVertical:5,
    padding:5,
    backgroundColor:'#75a7ff',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'row',
  }
});
