import React, { useState,useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import { ActivityIndicator, Platform, StyleSheet,TouchableOpacity,useWindowDimensions,Linking,View,Text} from 'react-native';
import {PopMenu,Button} from "../../../../assets/component";
import COLORS from '../../../../assets/component/colors';
import Ionicons from '@expo/vector-icons/Ionicons';
import {GetDocument} from "../../../../assets/js/firebase";
import {Invoice} from "../../../../assets/js/encyrpt";
import {CancelOrder,GetDocsByids} from "../../../../assets/js/firebase";
import { PDFDownloadLink,PDFViewer} from "@react-pdf/renderer";
import PDFile from './pdf_invoice';
import AddCreditNote from '../add';
import Details from './details';
import Products from './products';
import PDFPackingList from './pdf_packinglist';
import {GETACC} from '../../../../assets/js/getacc';

export default function InvoiceScreen({route,navigation}) {


  const {bill} = route.params;

  const [isLoading, setIsloading] = useState(false);
  const [item, setItem] = useState(null);
  const [productList, setProductList] = useState([]);
  const [billdetail, setBillDetail] = useState(bill);
  const [user, setUser] = useState([]);
  const [ivlink, setIvlink] = useState('');
  const [creditnote, setCreditNote] = useState(null);

  const [modified, setModified] = useState(null);

  useEffect(() => {
      getList(bill)

  }, []);

  async function getList(id){

    const user = await GETACC();
    setUser(user)

    const Link = await Invoice(id)
    setIvlink(Link)

   const datas = await GetDocument(id,'orders')
    setIsloading(true)
    if (datas) {
      setItem(datas);
      LinkData(datas.products)
      setIsloading(false)
    } else {
      console.log("No such document!");
      setIsloading(false)
    }
  };

  function VerifyRemove(data){
    if (!data.payments || data.payments.length == 0 && data.status !== 'paid' ) {
      if (confirm('Are you sure you want to delete ?')) {
        RemoveOrder(data.id)
      } else {
        console.log('Cancel Delete');
      }
    }else{
      alert("Cant delete there are payment has been made for this order");
    }
  }


  async function RemoveOrder(id){
    const status = await CancelOrder(id,'orders')
    if(status){
      navigation.navigate('orders')
    }else{
      alert("Error Cancel Order")
    }
  }

  const Print = (item) => {
    return (
      <PDFDownloadLink document={<PDFile details={item} user={user}/>} fileName={user.code+'-'+item.id}>
        {({loading})=>  Menu('download-outline','Save',null,loading) }
      </PDFDownloadLink>
    )
  }

  const PackingBTn = () => {
    if(!productList.length) return null
    return (
      <PDFDownloadLink document={<PDFPackingList details={productList} user={user} customer={item} />} fileName={'packinglist_'+bill}>
        {({loading})=> Menu('download-outline','Packing List',null,loading)}
      </PDFDownloadLink>
    )
  }

  const PrintView = () => {
    if(!productList.length) return null
    return (
      <PDFViewer style={{flex:1}}>
        <PDFPackingList details={productList} user={user} customer={item} />
      </PDFViewer>
    )
  }



  if(isLoading){
    return  <ActivityIndicator size={30}/>
  }

  if(!item){
    return null
  }



  const PaymentList = (data) => {
    if(!data) return null
    const listItems = data.map((item) =>
    <TouchableOpacity key={item.id} onPress={()=>navigation.navigate('debit', {bill: item.id,year:item.year})}>
      <View style={{flexDirection:'row'}}>
        <View style={{flex:1}}><Text style={{flex:3}}>{item.id}</Text></View>
        <View style={{flex:1}}><Text style={{flex:3}}>RM{parseFloat(item.amount).toFixed(2)}</Text></View>
      </View>
    </TouchableOpacity>
  );
  return listItems;
  }

    

  async function LinkData(data){
    let result = data.map(({ pid }) => pid ? pid : '7777')
    const Results = await GetDocsByids('products',result)
    const NewStock = []
    
    data.map(item => {
        const obj = Results && Results.length ? Results.find(o => o.id === item.pid ) : {}
        NewStock.push({"type":item.type,'image':obj && obj.image,"qty":item.qty,"name":item.name,"amount":item.amount,"id":item.id,"pid":item.pid})
    });

    setProductList(NewStock)
  }



 const H1 = (title) => {
  return  <View style={{paddingBottom:5,borderBottomWidth:1,marginBottom:5,borderColor:COLORS.Background}}><Text style={{fontWeight:'500',fontSize:16,color:COLORS.Link}}>{title}</Text></View>
 }

 

 const MenuBar = () => {
    return (
      <View style={{flexDirection:'row',zIndex:99}}>
          {Menu('chevron-back','',()=>navigation.navigate('orders'))}
          <View style={{flex:1}}/>
          <PopMenu  name={'Company'} 
                    menu={[
                      Print(item),
                      InvoiceLink(item),
                      Menu('trash-bin','Delete',()=>VerifyRemove(item)),
                      CreditNoteBtn(item),
                      PackingBTn(),
                      ]} />
      </View>
    )
 } 

 const CreditNoteBtn = (data) => {
  if(data.credit_note) return <ViewButon/>
  return (
    <TouchableOpacity style={styles.menulist} onPress={()=>setModified(data)}>
        <Ionicons name={'cash'} size={18} color={'#aaa'} /> 
        <Text style={[styles.menutext,{color:'#777'}]}>{"Credit Note"}</Text>
    </TouchableOpacity>
  )
 }


const ViewButon = () => {
  return (
  <TouchableOpacity style={styles.menulist} onPress={()=>navigation.navigate('creditnote')}>
      <Ionicons name={'cash'} size={18} color={'#ccc'} /> 
      <Text style={[styles.menutext,{color:'#ccc'}]}>{"View Credit Note"}</Text>
  </TouchableOpacity>
  )
}
 



 const InvoiceLink = (item) => {

  return Menu('receipt','Invoice',()=>Linking.openURL(ivlink))
 }


 

 const Menu = (icon,name,exc,loading) => {
  if(loading) return null
  return (
    <TouchableOpacity style={styles.menulist} onPress={exc}>
        <Ionicons name={icon} size={18} color={'#aaa'} /> 
        <Text style={[styles.menutext]}>{name}</Text>
    </TouchableOpacity>
  )
 }





  return (
    <View style={styles.container}>
      <MenuBar/>
      <View>
          <Details data={item} />
          <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
          {H1('Products')}
          <Products products={productList} bill={billdetail}/>
          {H1('Payments')}
          {PaymentList(item.payments)}
      </View>
      <AddCreditNote add={()=>navigation.navigate('creditnote')} visible={modified?true:false} setvisible={setModified} data={modified} creditnote/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  th:{
    fontWeight:'500',
    color:'#444',
    fontSize:12
  },
  menulist:{
    width:110,
    padding:10,
    flexDirection:'row'
  },
  menutext:{
    paddingLeft:5,
    textTransform: 'capitalize',
    fontSize:12,
    color:'#777'
  },
});
