import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text} from 'react-native';
import Table from './table';



export default function Cat_List(props) {

  const {cat,list,sub,edit,navSubcat} = props

  const [activeType, SetActive] = useState(null);


  if(!cat.length) return null

  const ListItem = (data,list) => {
    if(activeType !== data) return null
        return(
          <Table 
          list={list}
          loading={false}
          navSubcat={navSubcat}
          sub={sub}
          edit={edit}
          hide={true}
        /> 
      )
  }

  function Expand(key){
    if(key == activeType){
      SetActive(null)
    }else{
      SetActive(key)
    }
  }

      return(
         <View style={[styles.container]}>
         {cat.map(key => {
            const filterItem = list.filter(val => val.category.sub_category == key);
            const Exp = key !== activeType ? '+' : '-'
             return (
              <View key={key}>
                 <TouchableOpacity key={key} style={styles.box} onPress={()=>{Expand(key)}}>
                     <View style={{flex:1}}><Text style={styles.text}> {key + ' ('+filterItem.length+')'}</Text></View>
                     <View style={{paddingHorizontal:5}}><Text style={styles.text}>{Exp}</Text></View>
                 </TouchableOpacity>
                 {ListItem(key,filterItem)}
              </View>
             ); 
             })}
         </View>
     )





}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  box:{
    marginBottom:10,
    padding:10,
    backgroundColor:'#aecad9',
    borderRadius:5,
    flexDirection:'row'
  },
  text:{
    fontSize:16
  }
});
