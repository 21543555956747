import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,ActivityIndicator} from 'react-native';
import moment from 'moment';
import {Ionicons} from '@expo/vector-icons';



export const CreditRow = (item) => {
  const name = item.category?item.category.name:item.name
  return (
    <View style={{flex:1,marginBottom:4}}>
      <Text style={styles.title}>{name}</Text>
      <View style={styles.flexcenter}>
        <Text style={styles.category}>{item.category.category}</Text>
        <Ionicons name="chevron-forward" size={10} style={{marginTop:4}} color="#ccc" />
        <Text style={styles.category}>{item.category.sub_category}</Text>
      </View>
    </View>
  )
}

export const DebitRow = (item) => {
  if(!item.name) return null
  return (
    <View>
      <Text style={[{color:!item.orderid && '#038399'}]}>{item.name}</Text>
      {item.orderid&&<Text style={styles.category}>Order id : {JSON.stringify(item.orderid)}</Text>}
      {item.shopee_orders&&<Text style={styles.category}>total orders : {item.shopee_orders.length}</Text>}
      <CreditNote data={item}/>
    </View>
  )
}

const CreditNote = (props) => {
  const {data}  = props
  if(data.payment_method !== 'credit note') return null
  return <Text style={{color:'#b895ea'}}>Credit Note</Text>
}


const styles = StyleSheet.create({
    container:{
        flex: 1,
      },
      flexcenter:{
        alignContent:'center',
        flexDirection:'row',
        alignItems:'center',
      },
      item:{
        color:'#777',
        fontSize:14
      },
      price:{
        marginRight:5,
        color:'#777',
        fontSize:14,
        fontWeight:'500'
      },
      title:{
        color:'#777',
        fontSize:14,
      },
      ids:{
        color:'#aaa',
        fontSize:14
      },
      option:{
        borderRadius:5,
        padding:2,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
        paddingHorizontal:10,
        marginRight:5,
        shadowOffset: { width: 2, height: 2 },
            shadowRadius: 5,
            shadowOpacity: 0.2,
            elevation: 15,
      },
      category:{
        fontSize:14,
        color:'#479aca'
      },
  });
  