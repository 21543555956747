import moment from 'moment';

export function FormatDate(data,format) {
  if(!data) return null
  const Format = format?format:'DD/MM/YY'
  return moment(data.toDate()).format(Format);
}
  
export function Emai(cname) {
    return "";
}
  
  
  