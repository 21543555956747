import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,ActivityIndicator,Linking} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import {GetDocument } from "../../../../../assets/js/firebase";
import COLORS from "../../../../../assets/component/colors";


export default function DoLINK(props) {

    const {data,account,type,modal} = props

    const [products, setProducts] = useState([]);
    const [loading, setIsloading] = useState(false);

    const orderType = type?type:data.type

    const loadOrder = (data) => {
  
        async function Getdata(){
          setIsloading(true)
          const DataList  = await GetDocument(data.id,orderType)
          const FilterNonservice = DataList.products.filter(({type}) => !type.includes('service'))
          setProducts(FilterNonservice)
          setIsloading(false)
        }
        
        if(loading){
            return <ActivityIndicator size={'small'}/>
        }
  
        return(
          <TouchableOpacity style={styles.btn} onPress={()=>Getdata()}>
            <Ionicons name='logo-dropbox' size={16} color={COLORS.Link} />
            <Text style={styles.link}>Get Product</Text>
          </TouchableOpacity>
        )
  }


        if(data.proof) return null
        if(modal == 'order' && !data.driver) return null

        
        const driverMobile  = modal == 'delivery'?data.mobile : data.driver.mobile
  
        const Link = 'http://deliverydkz.web.app/delivery/'+account+'/'+data.id+'/'+orderType+'/'+driverMobile.slice(2)
  
  
        const Whatsapptxt = data.delivery+'%0a'+'%0a%0a'+Link


        const ProductListing = () => {

            if(!products.length) return null
            
              return(
                <View>
                  <Text style={{fontWeight:'500',color:COLORS.Primary}}>Products</Text>
                  <View style={{flexDirection:'row'}}><Text style={{flex:1,fontWeight:'500'}}>#</Text><Text style={{flex:5,fontWeight:'500'}}>Name</Text><Text style={{flex:1,fontWeight:'500'}}>Qty</Text></View>
                  {products.map((val,i)=> {
                      return (
                        <View key={i} style={{flexDirection:'row'}}>
                          <Text style={[styles.product,{flex:1}]}>{(i+1)}</Text>
                          <Text style={[styles.product,{flex:5}]}>{val.name}</Text>
                          <Text style={[styles.product,{flex:1}]}>{val.qty}</Text>
                        </View>
                      )
                })}
                </View>
              )
            
          }


          function WhatsappLink(data){

            var Whatsapp = '*Delivery Notification*'+'%0a%0a'
        

              const Link = 'http://deliverydkz.web.app/delivery/'+account+'/'+data.id+'/'+orderType+'/'+driverMobile.slice(2)
              Whatsapp += (1)+'. 📍'+data.delivery
              Whatsapp += "%0a . *MOBILE* : "+data.customer_mobile.substring(2)
              Whatsapp += "%0a . *NAME* : "+data.customer_name
              Whatsapp += '%0a . *DO*  : `' +Link+'` %0a%0a'

              Whatsapp += '%0a'+'> Please sign all DO with photo of proof on the link for each order'
        
              Linking.openURL('whatsapp://send?text='+Whatsapp+'&phone='+driverMobile)
          }
  
  
        return (

        <View>
          <View style={{flexDirection:'row',flexWrap:'wrap'}}>
                <TouchableOpacity style={styles.btn}  onPress={()=>window.open(Link, '_blank')}>
                      <Ionicons name='link' size={16} color={COLORS.Link} />
                      <Text style={styles.link}>DO</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btn} onPress={()=>WhatsappLink(data)}>
                      <Ionicons name='logo-whatsapp' size={16} color={COLORS.Link} />
                      <Text style={styles.link}>Whatsapp</Text>
                </TouchableOpacity>
                {loadOrder(data)}
          </View>
          <ProductListing/>
        </View>
        )


}

const styles = StyleSheet.create({
  text:{
    textTransform: 'capitalize',
    fontSize:16,
  },
  btn:{
    flexDirection:'row',
    alignContent:'center',
    padding:2,
    paddingHorizontal:5,
    marginBottom:5,
    marginRight:5,
    alignItems:'center',
    justifyContent:'center',
    borderWidth:1,
    borderColor:'#eee',
    borderRadius:3
},
deliverytxt:{
    color:COLORS.Link,
    fontSize:14
  },
  link:{
    color:COLORS.Link,
    fontSize:16,
    marginLeft:2
  },
});
