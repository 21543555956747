export function ValidMobile(data) {

    if(!data){
      return null;
    }

    var Input = data.trim()
    Input = data.replace(/\D/g,'').replace('+','');

    Input.substring(0,3)

    if(Input.substring(0,3)=== '601' && Input.length >= 11 && Input.length <= 12){
        return '+'+Input;
    }

    if(Input.substring(0,2) === '01' && Input.length >= 10 && Input.length <= 11){
      return '+6'+Input;
    }

    if(Input.charAt(0) === '1' && Input.length >= 9 && Input.length <= 10){
      return '+60'+Input;
    }

    return null;
  }


  
export function ValidationObj(obj,values){
    var validated = true
    obj.map((name)=>{
        if(!values[name] || values[name].error){
            setValues((prevState) => ({ ...prevState, [name]: {error:"Required"} }));
            validated = false
        }
    })
    return validated
}


  
export function Emai(cname) {
    return "";
}
  
  
  