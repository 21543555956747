import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,ActivityIndicator} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import moment from 'moment';
import {YearSelector} from '../../../assets/component/momentselect';
import { PDFDownloadLink,PDFViewer} from "@react-pdf/renderer";
import { ListDownoverlay,Button,Text,TextInput} from '../../../assets/component';
import DatePicker from "../../../assets/component/datepicker";
import {GETACC} from '../../../assets/js/getacc';
import COLORS from '../../../assets/component/colors';
import {GetBydateCreditDebits,GetByIdNameCreditDebits,GetDocument} from "../../../assets/js/firebase";
import PDFile from './statement/pdf_statement';
import { CSVLink, CSVDownload } from "react-csv";

export default function Reports(props) {

    const {data,setdata} = props
    const [user, setUser] = useState([]);

    const [searched, setSearched] = useState(false);
    const [option, SetOption] = useState("date");
    const [bytype, onByType] = useState({'active':'credit','type':'ID','name':''});
    const [byDated, onByDated] = useState({'from':moment().format("DD-MM-YYYY"),'to':moment().format("DD-MM-YYYY")});

    const [debitreport, setDebitreport] = useState(null);


    const [isloading, setIsloading] = useState(false);



    useEffect(() => {

      
    }, []);

    

    
  async function getMonthList(){
    setSearched(true)
    setIsloading(true)
    const user = await GETACC();
    setUser(user)

    const from = byDated['from']
    const to = byDated['to']
    
    if(option == 'date'){
      if(from && to){
        const ListCredit = await GetBydateCreditDebits('credit',from,to)
        const ListDebit = await GetBydateCreditDebits('debit',from,to)
        setdata({'credit':ListCredit,'debit':ListDebit})
        DebitReport(ListDebit,user)
      }
    }

    if(option == 'idname'){
      const Name = bytype['name']
      if(Name){
        const ListCredit = await GetByIdNameCreditDebits(bytype,from,to)
        setdata({...data, [bytype['active']]:ListCredit})
      }
    }
    setSearched(false)
    setIsloading(false)
};
    


    const DownloadLink = (type) => {
      const from = byDated['from']
      const to = byDated['to']
      return (
          <PDFDownloadLink document={<PDFile details={data[type]} user={user} month={byDated} type={type} />} fileName={type+'-'+from+'-'+to}>
              <TouchableOpacity><Text style={{color:COLORS.Link,marginRight:10}}>Download</Text></TouchableOpacity>
           </PDFDownloadLink>
      )
    }

    async function DebitReport(data,user){
      const {display_name} = user
      const arr = []
      const Inv = display_name == 'Design' ? 'DS-' : 'IV-'
      const aaa = await Promise.all(data.map(async item => {
        if(item.orderid){
          if(typeof item.orderid !== 'object'){
              const asyncRes = await GetDocument(item.orderid,'orders');
              if(asyncRes.products.length){
                asyncRes.products.map((item2,idx)=>{
                  const Total = item2.qty * item2.amount
                  arr.push({'date':ReformatTime(item.date),'invoice':Inv+item.orderid,'receipt':item.id,'type':'donkeez','name':asyncRes.name,'mobile':asyncRes.mobile,'product':item2.name,'amount':item2.amount,'qty':item2.qty,'total':Total,'paid':idx == 0 ? item.amount:''})
                })
              }
          }else{
            await Promise.all(item.orderid.map(async (sub)=>{
              const asyncRes = await GetDocument(sub,'orders');
              const Name = asyncRes.company?asyncRes.company:asyncRes.name
              if(asyncRes.products.length){
                asyncRes.products.map((item2,idx)=>{
                  const Total = item2.qty * item2.amount
                  arr.push({'date':ReformatTime(item.date),'invoice':Inv+sub,'receipt':item.id,'type':'donkeez','name':Name,'mobile':asyncRes.mobile,'product':item2.name,'amount':item2.amount,'qty':item2.qty,'total':Total,'paid':idx == 0 ? item.amount : ''})
                })
              }
            }))
          }

        }
        if(item.shopee_orders){
          const asyncRes2 = await Promise.all(item.shopee_orders.map(async(item) => await GetDocument(item,'online_order')));
          asyncRes2.map((item3)=>{
            item3.products.map((item4)=>{
              const Total = item4.qty * item4.amount
              arr.push({'date':ReformatTime(item.date),'invoice':'S'+Inv+item3.id,'receipt':item3.orderid,'type':item3.company,'name':item3.name,'mobile':item3.mobile,'product':item4.name,'amount':item4.amount,'qty':item4.qty,'total':Total,'paid':item.amount,'total':Total})
            })
          })

        }
      }))

      if(aaa){
        const helper = {}

        const result = arr.reduce((acc, arr2) => {
          const {receipt,type} = arr2
          if (helper[receipt]) {
            if(helper[type]){
              arr2['type'] = ''
              arr2['paid'] = ''
              return [...acc, arr2]
            }
            arr2['name'] = ''
            arr2['mobile'] = ''
            arr2['type'] = ''
            return [...acc, arr2]
          }

          helper[receipt] = 'x';
          helper[type] = 'x';
          return [...acc, arr2]
        }, [])
        setDebitreport(result)
      }
    }

    const ReformatTime = (data) => {
      if(!data) return null
      const format = moment(data.toDate()).format('DD/MM/YY');
      return format
  }


    const DownloadDebit = () => {
      const {display_name} = user
      if(!debitreport) return null
      const from = byDated['from']
      const to = byDated['to']
      return(
        <CSVLink data={debitreport} header={['date','id','name','mobile','product','amount']}  filename={display_name+"_debit_report-"+from+'-'+to} >Download Debit Report</CSVLink>
      )
    }


    const BulletOption = (type) => {

      const Bullet = () => {
        const color = type !== option ? null : '#444'
        return <View style={{width:8,height:8,backgroundColor:color,borderRadius:8}}/>
      }
      
      return (
        <TouchableOpacity onPress={()=>Optioned(type)} style={{borderWidth:1,marginRight:5,borderRadius:10,padding:2,alignItems:'center',justifyContent:'center'}}>
            {Bullet()}
        </TouchableOpacity>
      )
    }


    const TotalTranscation = () => {
      if(isloading) return <ActivityIndicator size={'small'}/>
      if(!data['credit'].length && !data['debit'].length) return null


      return (
        <View style={{padding:5}}>
          <Text style={{fontSize:12,color:'#aaa'}}>{data['credit'].length} Credit Total {DownloadLink('credit')}</Text>
          <Text style={{fontSize:12,color:'#aaa'}}>{data['debit'].length} Debit Total {DownloadLink('debit')}</Text>
          <DownloadDebit/>
        </View>
      )
    }


 function Optioned(val){
  SetOption(val)
  setSearched(false)
 }


    const ByDate = () => {

      return (
        <View style={{flexDirection:'row',zIndex:22,alignItems:'center'}}>
                  {BulletOption('date')}
                  <View style={{width:80,marginRight:10}}><Text>By Date</Text></View>
                  <View style={{flex:1,marginRight:10}}><DatePicker set={(val)=>{onByDated({...byDated, ['from']:val}),Optioned('date')}} value={byDated['from']} title={'From'}/></View>
                  <View style={{flex:1,marginRight:10}}><DatePicker set={(val)=>{onByDated({...byDated, ['to']:val}),Optioned('date')}} value={byDated['to']} title={'To'}/></View>
        </View>
      )
    }


    const ByIDname = () => {
      return (
        <View style={{flexDirection:'row',zIndex:24,alignItems:'center'}}>
                  {BulletOption('idname')}
                  <View style={{width:80,marginRight:10}}><Text>By</Text></View>
                  <View style={{flex:1,marginRight:10}}><ListDownoverlay list={['credit','debit']} active={bytype['active']} onPress={(val)=>onByType({...bytype, ['active']:val})}/></View>
                  <View style={{flex:1,marginRight:10}}><ListDownoverlay list={['ID','Name']} active={bytype['type']} onPress={(val)=>onByType({...bytype, ['type']:val})}/></View>
                  <View style={{flex:2}}><TextInput onChangeText={(val)=>{onByType({...bytype, ['name']:val}),Optioned('idname')}} value={bytype['name']} placeholder="ID or Name" /></View>
        </View>
      )
    }




        return (
          <View style={{padding:5,zIndex:19}}>
            <View style={{flex:1,zIndex:22}}>
              {ByDate()}
              {ByIDname()}
              {TotalTranscation()}
            </View>
            {!searched && <Button title={'search'} icon={'search'} onPress={()=>getMonthList()} color={'#706cac'}/>}
          </View>
        )

  }




const styles = StyleSheet.create({
  container:{
    zIndex:32
  },
  detailsbx:{
    borderBottomWidth:1,
    borderColor:'#eee',
    padding:5,
    backgroundColor:'#fff5d4',
    flexDirection:'row',
    zIndex:32
  },
  btnmore:{
    flexDirection:'row',
    padding:5,
    justifyContent:'center'
  }
});
