import React, { useState, useEffect } from 'react';
import {Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import {MaterialIcons} from '@expo/vector-icons';
import COLORS from "../../../../../assets/component/colors";
import {DatePicker,Button,Modal,TextInput,Form} from "../../../../../assets/component";


export default function SelectedProducts(props) {

    const {set,data,list,status,creditnote} = props

    const [edit, setEdit] = useState(false);


    
    useEffect(() => {
        setEdit(false)
    }, []);


    const onChangePrice = (index,val) => {
        var Amount = val.replaceAll(",", "");
        set((prev) =>
          prev.map((userScore,idx) => {
            if (idx === index) {
              return {
                ...userScore,
                amount: Amount?Amount.replace(/^0+/, ''):'0'
              };
            } else {
              return userScore;
            }
          })
        );
      };

      const onChangeQty = (index,val,stk) => {
        console.log(stk)
        const qty = val?parseInt(val):0
        set((prev) =>
          prev.map((userScore,idx) => {
            if (idx === index) {
              return {
                ...userScore,
                qty: parseInt(qty)
              };
            } else {
              return userScore;
            }
          })
        );
      };

      function RemoveProducts(index){

        if(list){
        const temp = [...data];
        temp.splice(index, 1);
        set(temp);
        if(!temp.length){
            list(true)
        }
      }
      }



    const headerProducts = () => {
        return (
          <View style={{flexDirection:'row',padding:2}}>
            <View style={{flex:1}}><Text style={styles.th}>ID</Text></View>
            <View style={{width:50}}><Text style={styles.th}>QTY</Text></View>
            <View style={{width:100}}><Text style={styles.th}>PRICE</Text></View>
            <View style={{width:20}}></View>
          </View>
        )
      }
  


    function qtyChanger(i,val,stock){
        if(val <= stock){
            onChangeQty(i,val,stock)
        }
    }

    const RemoveBtn = (i,status) => {
        const Icon = status ? 'close' : 'edit'
        return (
            <View style={{alignContent:'center',width:20,justifyContent:'center',alignItems:'center'}}>
                    <TouchableOpacity onPress={()=>status ? RemoveProducts(i) : setEdit(true)} style={{width:20}}>
                        <MaterialIcons name={Icon} size={18} color={COLORS.Error} />
                    </TouchableOpacity>
            </View>
        )
    }

      const Display = (data) => data.map((i, index) => {

          const Stock = i.type == 'stock'?true:false
          const DisableInp = status == 'pending' || !status || edit? true : false

          return (
              <View style={[styles.orderSelect,{ backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }]} key={"Selected"+index}>
                  <View style={{flex:4,padding:5}}>
                      <Text>{i.name}</Text>
                      {Stock &&
                        <View style={{flexDirection:'row'}}> 
                          <Text>stock : </Text>
                          <Text style={{color:COLORS.Link}}>{i.stock}</Text>
                        </View>
                      }
                  </View>
                  <View style={{width:50,marginRight:5}}>
                      {Stock && <TextInput onChangeText={(val)=>qtyChanger(index,val,i.stock)} value={i.qty} uneditable={DisableInp}/>}
                  </View>
                  <View style={{width:100}}>
                      <TextInput onChangeText={(val)=>onChangePrice(index,val)} value={i.amount} uneditable={DisableInp}/>
                  </View>
                  {RemoveBtn(index,DisableInp)}
              </View>
          )
      })

      const AdditemBtn = () => {
        if(!list) return <View style={{flex:1}}/>
        return (
          <TouchableOpacity style={styles.additem} onPress={()=> list(true)}>
                    <MaterialIcons name='add-box' size={22} color={COLORS.Primary} />
                    <Text style={{color:COLORS.Primary,marginLeft:2}}>ADD ITEM</Text>
          </TouchableOpacity>
        )
      }

      if(!data.length) return null
      

      return (
          <View>
              {headerProducts()}
              {Display(data)}
              <View style={{alignItems:'center',flexDirection:'row',padding:5,marginRight:20}}>
                  <AdditemBtn/>
                  <Text style={{fontWeight:'bold',fontSize:18}}>RM{data.reduce((n, {amount,qty}) => n + (parseFloat(amount)*parseInt(qty)), 0).toFixed(2)}</Text>  
              </View>
          </View>
      )


}

const styles = StyleSheet.create({
  list:{
    flexDirection:'row',
    borderWidth:0.5,
    borderRadius:4,
    padding:5,
    marginRight:5,
    marginBottom:5,
  },
  orderSelect:{
    padding:5,
    flexDirection:'row',
    borderBottomWidth:1,
    borderStyle:'dashed',
    borderColor:'#cecece'
  },
  th:{
    fontWeight:'600',
    color:COLORS.Primary
  },
  additem:{
    borderWidth:1,
    padding:5,
    marginVertical:5,
    flex:1,
    borderStyle:'dashed',
    marginRight:10,
    borderRadius:5,
    borderColor:COLORS.Primary,
    alignItems:'center',
    alignContent:'center',
    flexDirection:'row',
    justifyContent:'center'
  },
});
