import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet,View } from 'react-native';
import Tab from './tab';
import Table from './table';
import {SearchBox,Text} from "../../../assets/component";
import {GetOrders,DeleteCreditNote} from "../../../assets/js/firebase";
import {GETACC} from '../../../assets/js/getacc';


export default function OrderScreen({route,navigation}) {

  const LimitPerload = 12

  const isMounted = useRef();

  const DafaultTab = {pending:[],executed:[],cancel:[]}


  const [list, setList] = useState(DafaultTab);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);
  const [isLoading, setIsloading] = useState(false);
  const [lastVisible , setLastVisible ] = useState(null);
  const [userd, setUserd] = useState([]);


  useEffect(() => {
    
      isMounted.current = true;
      if (isMounted.current) {
        if(list[tabactive].length == 0){
          setIsloading(true)
          getList(tabactive);
        }
      }
      return () => {
        isMounted.current = false;
      };
    }, [tabactive]);



  async function getList(active){
    const userdata = await GETACC();
    setUserd(userdata)

    const ListOrders = await GetOrders('credit_note',active,LimitPerload)
    if(isMounted.current){
      setLastVisible({...lastVisible, [active]:ListOrders.last});
      setList({...list, [active]:ListOrders.list});
      setIsloading(false)

    }

  };

  
  async function Delete(data){
    const status = 'cancel'
    data['status'] = status

     const Stat = await DeleteCreditNote(data)
    setIsloading(true)
    const filteredData = list[tabactive].filter(item => item.id !== data.id);
    const dataArray = [ ...list[status]]
    dataArray.unshift(data)
    setList({...list, [tabactive]:filteredData,[status]:dataArray});
    setIsloading(false)
  }

  async function nextList(active,last){

      if(!last[active]) return null

      const ListOrders = await GetOrders('credit_note',active,LimitPerload,last[active])

      if(ListOrders){
        console.log("load More")
        setLastVisible({...lastVisible, [active]:ListOrders.last});

        const dataArray = [ ...list[active], ...ListOrders.list]
  
        setList({...list, [active]:dataArray});
        setIsloading(false)
      }
    
  };
  







  return (
    <View style={styles.container}>
      <Text style={styles.head}>Credit Note</Text>
      <View style={styles.tabBx}>
        <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab} />
        {!isLoading &&<SearchBox set={(val)=>setList({...list, [tabactive]:val})} products={list[tabactive]} filters={['name', 'company','id']}/>}
      </View>
      <Table 
        tab={tabactive} 
        setList={setList} 
        list={list} 
        user={userd}
        del={Delete}
        next={()=>nextList(tabactive,lastVisible)} 
        loading={isLoading}
        nav={navigation}
        />




    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  head:{
    fontSize:22,
    fontWeight:'700'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
});
