import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,FlatList,TouchableOpacity } from 'react-native';
import {TextInput,Form} from "../../../../assets/component";
import Ionicons from '@expo/vector-icons/Ionicons';
import { cond } from 'react-native-reanimated';


export default function SuggestList(props) {

  const {data,set,values} = props

  const {name,id,driver_id} = values
  const [items, setItems] = useState({});


  useEffect(() => { 

    setItems(data)

  }, []);



  useEffect(() => { 
    if(name && !name.error){
      const newData = data.filter(item => item.name.toLowerCase().includes(name.toLowerCase()))
      setItems(newData);
    }else{
      setItems(data);
    }

  }, [name]);


  function Selected(arr){

    const InHouse = arr['in-house']?arr['in-house']:false

    const ReArr = {
        'driver_id': arr.id,
        'company_transporter': InHouse,
        'in-house': InHouse,
        'mobile': arr.mobile,
        'name': arr.name,
        'bank': arr.bank?arr.bank:null,
        'account': arr.account?arr.account:null,
        'customer_id': arr.customer_id?arr.customer_id:null
    }
    set(ReArr)
  }


  const RenderItem = ({item,index}) => {
    const {name,id,mobile,bank,acc} = item;
    return(
      <TouchableOpacity style={{flex:1,borderBottomWidth:1,padding:5,borderColor:'#ddd'}}  onPress={()=>Selected(item)}>
            <Text style={{fontsize:16}}>{name}</Text>
            <View style={styles.catrow}>
              <Text style={styles.cat}>{mobile}</Text>
              <Ionicons name='chevron-forward' size={12} color={'#aaa'} />
              <Text style={styles.sub}>{bank}</Text>
            </View>
      </TouchableOpacity>
    )
  }




  if(!data || !items.length || !name || driver_id || name.error) return null



  return (
    <FlatList
        style={{borderWidth:1,maxHeight:200,borderColor:'#ccc',backgroundColor:'#eee'}}
        showsHorizontalScrollIndicator={false}
        data={items}
        renderItem={RenderItem}
        onEndReachedThreshold={0}
    />
  )



}

const styles = StyleSheet.create({

  selected:{
    flexDirection:'row',
    padding:5,
    borderWidth:1,
    borderColor:'#eee',
    backgroundColor:'#f2f2f2',
    marginBottom:5,
    borderRadius:5
  },
  cat:{
    fontSize:12,
    color:'#0076a8',
    marginRight:3
  },
  sub:{
    fontSize:12,
    color:'#aaa',
    marginLeft:3
  },
  catrow:{
    flexDirection:'row',
    alignItems:'center',
    alignContent:'center'
  }

});
