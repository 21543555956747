import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet,View,TouchableOpacity } from 'react-native';
import {getAuth} from 'firebase/auth';
import Tab from './tab';
import ADDORDER from './add';
import Table from './table';
import {SearchBox,Button,Text} from "../../../assets/component";
import {GetOrders} from "../../../assets/js/firebase";


export default function OrderScreen({route,navigation}) {

  const auth = getAuth();
  const user = auth.currentUser;

  const LimitPerload = 12

  const isMounted = useRef();


  const DafaultTab = {pending:[],paid:[],cancel:[]}


  const [list, setList] = useState(DafaultTab);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);
  const [isLoading, setIsloading] = useState(false);
  const [lastVisible , setLastVisible ] = useState(null);
  const [addModalvisible, setAddModalVisible] = useState(false);
  const [reorder, setReorder] = useState(null);



  useEffect(() => {
      isMounted.current = true;
      if (isMounted.current) {
        if(list[tabactive].length == 0){
          setIsloading(true)
          getList(tabactive);
        }
      }
      return () => {
        isMounted.current = false;
      };
    }, [tabactive]);



  async function getList(active){

    const ListOrders = await GetOrders('orders',active,LimitPerload)
    if(isMounted.current){
      setLastVisible({...lastVisible, [active]:ListOrders.last});
      setList({...list, [active]:ListOrders.list});
      setIsloading(false)

    }

  };

  async function nextList(active,last){

      if(!last[active]) return null

      const ListOrders = await GetOrders('orders',active,LimitPerload,last[active])

      if(ListOrders){
        console.log("load More")
        setLastVisible({...lastVisible, [active]:ListOrders.last});

        const dataArray = [ ...list[active], ...ListOrders.list]
  
        setList({...list, [active]:dataArray});
        setIsloading(false)
      }
    
  };
  




  function PushItem(data){
    const dataArray = [ ...list[data.status]]
    dataArray.unshift(data)
    setList({...list, [data.status]:dataArray});
  }

  function Reorder(data){
    setReorder(data)
    setAddModalVisible(true)
  }






  return (
    <View style={styles.container}>
      <Text style={styles.head}>Order</Text>
      <View style={styles.tabBx}>
        <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab} />
        <Button title={'ADD ORDER'} icon={'md-add-circle'} style={{padding:2}} onPress={() => {setAddModalVisible(true),setReorder(null)}} color={'#42aff5'}/>
        {!isLoading &&<SearchBox set={(val)=>setList({...list, [tabactive]:val})} products={list[tabactive]} filters={['name', 'company','id']}/>}
        <ADDORDER add={PushItem} visible={addModalvisible} setvisible={setAddModalVisible} data={reorder}/>
      </View>
      <Table 
        tab={tabactive} 
        setList={setList} 
        list={list} 
        user={user}
        next={()=>nextList(tabactive,lastVisible)} 
        loading={isLoading}
        nav={navigation}
        reorder={(val)=>Reorder(val)}
        />




    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  head:{
    fontSize:22,
    fontWeight:'700'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
});
