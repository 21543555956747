import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View ,Image} from 'react-native';
import {Text} from "../../../assets/component";

export default function StaffDetails(props) {

  const {data} = props

  const [staff, setStaff] = useState(props.data);


  const Photo = () => {
    return (
      <View style={styles.photoc}>
        <Image style={styles.photobx} source={{ uri: staff.image}}/>
      </View>

    )
  }


  useEffect(() => {
    if(data){
      setStaff(data)
    }

  }, [data]);





  const Details = (data,val) => {
    return(
      <View style={{width:500}}>
        {
        data.map((key,i) => {
          return <Text key={i}>{val&& ": " }{key}</Text> 
        })
      }
      </View>
    )
  }
  



  if(!staff) return null



  return (
    <View style={styles.container}>
      <View style={{flex:1}}>
        <View style={{flex:1,flexDirection:'row'}}>
          <View style={{width:120}}>{Details(['NAME','MYKAD/ID','MOBILE','DOB','POSITION','BASIC','ANNUAL LEAVE','MEDICAL LEAVE','BANK','ACC'])}</View>
          <View>{Details([staff.name,staff.mykad,staff.mobile,staff.dob,staff.position,"RM"+staff.basic,staff.annual_leave,staff.medical_leave,staff.bank,staff.account],true)}</View>
        </View>
      </View>
      <Photo/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding:10,
    flexDirection:'row',
    borderWidth:1,
    borderColor:'#eee',
    borderRadius:5,
  },
  photoc:{
    width:150,
    alignContent:'center',
    alignItems:'center',
  },
  photobx:{
    width:130,
    height:130,
    backgroundColor:'#eee',
  },
  btn:{
    borderWidth:1,
    padding:5,
    margin:5,
    borderRadius:3,
    borderColor:'#ddd',
    flexDirection:'row'
  }
});
