import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import {TextInput} from "../../../../assets/component";


export default function InputDelivery(props) {

    const {value,set,platform} = props


    const [autoFill, setAutoFill] = useState(null);

    useEffect(() => {
      if(platform !== 'Lazada'){
        ShopeeFormat(value)
      }
    }, [value]);

    function ShopeeFormat(data){
      var regex = /\b['6','0','1','+']\d{8,12}/
      let output = data.match(regex)
      if(!output || !output.length){ return null }
      const Mobile = output && output[0]
      const MobileStr = Mobile.substring(Mobile.indexOf("1"));
      const AfterMobile = data.split(output[0]).pop().trim();
      const BeforeMobile = data.split(output[0]).shift().replaceAll(',', '').trim()
      if(BeforeMobile && MobileStr && AfterMobile){
        setAutoFill({'name':BeforeMobile,'mobile':MobileStr,'address':AfterMobile})
      }
      console.log({'name':BeforeMobile,'mobile':MobileStr,'address':AfterMobile})
    }




    const CloseBtn = () => {
      return (
        <TouchableOpacity onPress={()=> {setAutoFill(null);set('')}} style={styles.closebtn}>
        <Text style={{fontSize:12}}>X</Text>
      </TouchableOpacity>
      )
    }

    const ManualVerifyBtn = () => {
      if(platform !== 'Lazada') return null
      return (
        <View style={{alignItems:'baseline',justifyContent:'flex-end',padding:10}}>
          <TouchableOpacity onPress={()=>ShopeeFormat(value)}><Ionicons name='checkmark-circle' size={24} color={'green'} /></TouchableOpacity>
        </View>
      )
    }

    

      if(autoFill){
        return (
          <View style={{flexDirection:'row'}}>
            <View style={{flex:1}}>
                <Text>Name :{autoFill.name}</Text>
                <Text>Mobile : {autoFill.mobile}</Text>
                <Text>Address : {autoFill.address}</Text>
            </View>
                <CloseBtn/>
          </View>
        )
      }
      
      return(
        <View style={styles.container}>
              <View style={{flex:1}}><TextInput onChangeText={set} maxLength={560} title="Delivery Address" remark={'eg:{name} {mobile} {delivery address}'} value={value} errclear={true}/></View>
              <ManualVerifyBtn/>
        </View>
      )




}

const styles = StyleSheet.create({
      container:{
        flexDirection:'row',
        flex:1,
        width:'100%'
      },
      closebtn:{
        borderRadius:12,
        width:12,
        height:12,
        backgroundColor:'#999',
        alignContent:'center',
        alignItems:'center',
        justifyContent:'center'
      }
  });
  