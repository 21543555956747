import React, { useState,useEffect } from 'react';
import { StyleSheet } from 'react-native';
import {Form} from "../../../assets/component";
import COLORS from "../../../assets/component/colors";


export default function StaffForm(props) {

    const {data,set} = props


    const BankLIST = ['MAYBANK','CIMB','BANK ISLAM','AFFIN','ALLIANCE','AMBANK','BANK RAKYAT','HLB','HSBC','MUAMALAT','OCBC','PBB','RHB','UOB','BSN']



        return (
            <Form 
                data={
                            [
                                [{name:'file',display:'Image',image:true,format:'image/*',size:65},{name:'name',display:'Full Name'}],
                                [{name:'mykad',display:'Mykad',flex:1},{name:'mobile',display:'Mobile No',flex:1},{name:'dob',display:'DOB',date:true}],
                                [{name:'position',display:'Position'},{name:'basic',display:'Basic Salary(RM)'},{name:'epf_no',display:'EPF NO'}],
                                [{name:'perkeso_no',display:'PERKESO NO'}],
                                [{name:'medical_leave',display:'Medical Leave',remark:'total days (year)'},{name:'annual_leave',display:'Annual Leave',remark:'total days (year)'}],
                                [{name:'income_others',display:'Income Others',list:['name','amount'],others:true}],
                                ['Employer Contribution'],
                                [{name:'kwsp',display:'Kwsp'},{name:'socso',display:'PERKESO'},{name:'eis',display:'EIS'}],
                                ['Employee Contribution'],
                                [{name:'kwsp2',display:'Kwsp'},{name:'socso2',display:'PERKESO'},{name:'eis2',display:'EIS'},{name:'pcb',display:'PCB'}],
                                [{name:'deduction_others',display:'Deduction Others',list:['name','amount'],others:true}],
                                ['BANK'],
                                [{name:'bank',display:'Bank',flex:1,listdown:BankLIST},{name:'account',display:'Account No'}],
                            ]
                    }
                value={data}
                set={set}
            />
        )


}

const styles = StyleSheet.create({
    container:{
        width:'100%',
        zIndex:33,
        marginBottom:100
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
      head:{
        fontWeight:'400',
        color:COLORS.Primary
      },
      coltitle:{
        borderBottomWidth:1,
        padding:5,
        borderColor:'#eee',
        marginVertical:5
      }
  });
  