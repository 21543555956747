import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import {GetDocument } from "../../../../../assets/js/firebase";
import {FormatDate} from "../../../../../assets/js/format";
import moment from 'moment'

export default function Progress(props) {

    const {data} = props

    const [expand, setExpand] = useState(false);
    
      const DeliveryProg = data.delivery_status?[...data.delivery_status].reverse():[]
      const DataList = expand?DeliveryProg:[DeliveryProg[0]]


      const RenderItem = ({item,index}) => {
        const bulletstyle = index == 0 ? styles.bullet : styles.bullet2
        return(
            <View style={{flexDirection:'row'}} key={index}>
                    <View style={{marginRight:5,width:20,alignContent:'center',alignItems:'center'}}>
                      <View style={bulletstyle}/>
                      <View style={{flex:1,borderRightWidth:1,borderStyle:'dashed',borderColor:'#ccc',marginBottom:1}}/>
                    </View>
                    <View style={{flex:1}}>
                          <Text style={{fontSize:12,color:'#347e7e',fontWeight:'400'}}>{item.status.toUpperCase()}</Text>
                          <Text style={{fontSize:10,color:'#aaa',marginBottom:4}}>{FormatDate(item.updated,'DD/MM/YY hh:mm A')}</Text>
                    </View>
            </View>
        )
      }

      const ReformatTime = (data) => {
        if(!data) return null
        const format = moment(data.toDate()).format('DD/MM/YY HH:MM');
        return format
      }

      if(!data || !data.length) return null

      return (
        <View style={{margin:10}}>
            <TouchableOpacity onPress={()=>setExpand(!expand)} style={{flexDirection:'row'}}>
                <Text style={{color:'#777',marginRight:5}}>Delivery Progress</Text>
                <Ionicons name='add-circle' size={16} color={'#347e7e'} />
            </TouchableOpacity>
            <Text style={{fontSize:10,color:'#ccc',marginBottom:5}}>click more to view more progress</Text>
            <FlatList
            showsHorizontalScrollIndicator={false}
            data={DataList}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
            />
        </View>
      )



}

const styles = StyleSheet.create({
  btn:{
    flexDirection:'row',
    alignContent:'center',
    padding:2,
    paddingHorizontal:5,
    marginBottom:5,
    marginRight:5,
    alignItems:'center',
    justifyContent:'center',
    borderWidth:1,
    borderColor:'#eee',
    borderRadius:3
},
});
